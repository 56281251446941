import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { CartUpsellSlide } from './CartUpsellSlide';

export function CartUpsellSlider({ products, closeCart }) {
  return (
    <div>
      <Swiper
        grabCursor
        spaceBetween={0}
        slidesPerView={1}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        loop={products?.length > 1}
        a11y={{ enabled: true }}
        modules={[Navigation]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          disabledClass: 'cursor-not-allowed opacity-20',
        }}
        style={{
          '--swiper-navigation-color': 'black',
          '--swiper-navigation-size': '12px',
        }}
      >
        {products?.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <CartUpsellSlide
                product={product?.product || product}
                closeCart={closeCart}
              />
            </SwiperSlide>
          );
        })}

        <button
          aria-label="See previous slide"
          className="swiper-button-prev !left-2"
          type="button"
        />

        <button
          aria-label="See next slide"
          className="swiper-button-next !right-2"
          type="button"
        />
      </Swiper>
    </div>
  );
}

CartUpsellSlider.displayName = 'CartUpsellSlider';
