import { Schema } from './Banner.schema';
import { BannerContent } from './BannerContent';
import { BannerVideo } from './BannerVideo';
import { Image } from '@snippets';

export function Banner({ cms }) {
  const { section, image, video, button, content, text } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const isFullBleed = section?.fullBleed;
  const heightContainerClasses = `${section?.heightMobile} ${section?.heightTablet} ${section?.heightDesktop}`;

  return (
    <div className={`${isFullBleed ? '' : 'px-contained'}`}>
      <div
        className={`relative mx-auto flex flex-col bg-offWhite ${heightContainerClasses} ${maxWidthContainerClass}`}
      >
        <div className="relative h-full w-full">
          <div className="relative h-full w-full overflow-hidden md:hidden">
            {video?.srcMobile && (
              <BannerVideo
                isVisible
                posterSrc={video.posterMobile?.src}
                videoSrc={video.srcMobile}
              />
            )}

            {image?.imageMobile?.src && !video?.srcMobile && (
              <Image
                alt={image.alt}
                className={`${image.positionMobile}`}
                fill
                priority
                sizes="100vw"
                src={image.imageMobile.src}
              />
            )}
          </div>

          <div className="relative hidden h-full w-full overflow-hidden md:block">
            {video?.srcDesktop && (
              <BannerVideo
                isVisible
                posterSrc={video.posterDesktop?.src}
                videoSrc={video.srcDesktop}
              />
            )}

            {image?.imageDesktop?.src && !video?.srcDesktop && (
              <Image
                alt={image.alt}
                className={`${image.positionDesktop}`}
                fill
                priority
                sizes="100vw"
                src={image.imageDesktop.src}
              />
            )}
          </div>

          <BannerContent button={button} content={content} text={text} />
        </div>
      </div>
    </div>
  );
}

Banner.displayName = 'Banner';
Banner.Schema = Schema;
