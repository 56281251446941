import { useRef } from 'react';

export function PictureImage({
  aboveTheFold,
  alt,
  imageClassName,
  inView,
  initialSrc,
  onError = () => {},
  onLoad = () => {},
}) {
  const imageRef = useRef();
  return aboveTheFold || inView ? (
    <img
      ref={imageRef}
      alt={alt}
      src={initialSrc}
      className={`absolute inset-0 m-0 h-full w-full border-0 object-cover p-0 ${imageClassName}`}
      onLoad={() => onLoad(imageRef.current)}
      onError={(event) => {
        console.error('Error loading image with src', event.target.currentSrc);
        onError(event);
      }}
    />
  ) : null;
}

PictureImage.displayName = 'PictureImage';
