import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';
import { useCustomer, useSettings } from '@backpackjs/storefront';

import { Link, Svg, ProductItem } from '@snippets';
import { MenuNestedSidebar } from './MenuNestedSidebar';

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  nestedSidebarContent,
  handleNestedSidebar,
}) {
  const customer = useCustomer();
  const settings = useSettings();

  const {
    links: additionalLinks,
    menuItems,
    productsSlider,
  } = { ...settings?.header?.menu };
  const { products, heading: productsHeading } = {
    ...productsSlider,
  };

  return (
    <div
      className={`fixed left-0 z-[60] h-full w-full lg:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }
      ${
        nestedSidebarContent
          ? 'top-0'
          : 'top-[var(--header-height)] lg:top-[var(--header-height-desktop)]'
      }
      `}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute left-0 top-0 hidden h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-full flex-col overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
      >
        {menuSidebarOpen && (
          <div className="relative w-full flex-1 overflow-x-hidden">
            <div
              className={`scrollbar-hide h-full w-full overflow-y-auto ${
                nestedSidebarContent ? 'invisible' : 'visible'
              }`}
            >
              <nav className="flex">
                <ul className="w-full flex-col">
                  {menuItems?.map((item, index) => {
                    const hasContent =
                      item.links?.length > 0 || item.imageLinks?.length > 0;

                    return (
                      <li
                        key={index}
                        className="flex min-h-[3.5rem] w-full border-b border-b-lightGray"
                      >
                        {hasContent ? (
                          <button
                            aria-label={item.menuItem.text}
                            className="flex h-14 w-full items-center justify-between gap-5 p-4"
                            onClick={() => handleNestedSidebar(index)}
                            type="button"
                          >
                            <p className="text-title-h5 flex-1 text-left">
                              {item.menuItem.text}
                            </p>

                            <Svg
                              className="w-5"
                              src="/svgs/arrow-right.svg#arrow-right"
                              title="Arrow Right"
                              viewBox="0 0 24 24"
                            />
                          </button>
                        ) : (
                          <Link
                            aria-label={item.menuItem.text}
                            className="text-title-h5 flex h-14 w-full items-center p-4"
                            href={item.menuItem.url}
                            onClick={handleCloseSidebar}
                          >
                            {item.menuItem.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>

              {products?.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-title-h5 mb-2 px-4">{productsHeading}</h3>

                  <Swiper
                    modules={[A11y]}
                    slidesPerView={1.3}
                    spaceBetween={16}
                    slidesOffsetBefore={16}
                    slidesOffsetAfter={16}
                    grabCursor
                    className="mb-5"
                  >
                    {products.map(({ product }, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <ProductItem
                            handle={product.handle}
                            index={index}
                            onClick={handleCloseSidebar}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              )}

              <ul className="flex flex-col gap-y-5 p-4">
                {additionalLinks?.map(({ link }, index) => {
                  return (
                    <li key={index}>
                      <Link
                        aria-label={link?.text}
                        className="text-body"
                        href={link?.url}
                        onClick={handleCloseSidebar}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
                <li>
                  <Link
                    aria-label="Go to account page"
                    className="text-body"
                    href={customer ? '/account/orders' : '/account/login'}
                    onClick={handleCloseSidebar}
                  >
                    {customer ? 'Account' : 'Sign In'}
                  </Link>
                </li>
              </ul>
            </div>

            <MenuNestedSidebar
              handleCloseSidebar={handleCloseSidebar}
              handleNestedSidebar={handleNestedSidebar}
              nestedSidebarContent={nestedSidebarContent}
            />
          </div>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
