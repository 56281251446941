import { Image, Link } from '@snippets';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  menuDrawerContent,
}) {
  const { imageLinks, links } = { ...menuDrawerContent };
  const hasContent = imageLinks?.length > 0 || links?.length > 0;

  return (
    <div
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
    >
      {hasContent && (
        <div className="px-contained py-contained mx-[auto] grid max-w-[var(--content-max-width)] grid-cols-[1fr_360px] gap-x-[2rem] xl:gap-x-[4rem]">
          <div>
            <ul className="flex gap-x-[2rem] xl:gap-x-[4rem]">
              {links?.map(({ link, sublinks }, index) => {
                return (
                  <li className="w-full" key={index}>
                    <div className="mb-2">
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={link?.text}
                        className="hover-text-underline text-title-h5 max-xl:text-[15px]"
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                      >
                        {link?.text}
                      </Link>
                    </div>
                    {!!sublinks?.length && (
                      <ul className="flex flex-col gap-y-2.5">
                        {sublinks.map(({ link }, subLinkIndex) => {
                          return (
                            <li key={subLinkIndex}>
                              <Link
                                aria-hidden={!hasContent}
                                aria-label={link?.text}
                                className="hover-text-underline text-body max-xl:text-sm"
                                href={link?.url}
                                newTab={link?.newTab}
                                onClick={handleMenuDrawerClose}
                                tabIndex={hasContent ? '0' : '-1'}
                              >
                                {link?.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <ul
            className={`grid ${
              imageLinks?.length > 1
                ? 'grid-cols-2 gap-y-5 gap-x-[2rem] xl:gap-x-[4rem]'
                : 'grid-cols-1'
            }`}
          >
            {imageLinks
              ?.slice(0, 2)
              ?.map(({ alt, caption, image, link }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-hidden={!hasContent}
                      aria-label={caption}
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleMenuDrawerClose}
                      tabIndex={hasContent ? '0' : '-1'}
                    >
                      <div
                        className={`relative w-full overflow-hidden bg-offWhite
                      ${
                        imageLinks?.length > 1
                          ? 'aspect-[3/4]'
                          : 'aspect-[16/9]'
                      }
                    `}
                      >
                        {image?.src && (
                          <Image
                            alt={alt}
                            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                            height={Math.floor(400 / (16 / 9))}
                            src={image.src}
                            width="400"
                          />
                        )}
                      </div>

                      <p className="text-title-h5 mt-3 text-center max-xl:text-[15px]">
                        {caption}
                      </p>
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
