import {
  fetchCollectionFromHandle,
  fetchCollectionsFromHandles,
} from './collection';
import {
  fetchProductFromHandle,
  fetchProductsFromHandles,
  formatImageItemAsMediaItem,
} from './product';
import { requestReviewsIo } from './requestReviewsIo';
import { validateId } from './validateId';

export {
  fetchCollectionFromHandle,
  fetchCollectionsFromHandles,
  fetchProductFromHandle,
  fetchProductsFromHandles,
  formatImageItemAsMediaItem,
  requestReviewsIo,
  validateId,
};
