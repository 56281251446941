import { Fragment } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { ProductItem } from '@snippets';
import { CollectionPromoTile } from './CollectionPromoTile';

export function CollectionGrid({
  activeFilters,
  collectionProductsData,
  enabledFilters,
  isSearchPage,
  promoTiles,
  swatchesMap,
}) {
  const settings = useSettings();
  const { pagination, productItem } = { ...settings?.collection };

  const {
    state: { filteredProducts, productsLimit },
    actions: { loadMoreProducts },
    refs: { loadMoreRef },
  } = collectionProductsData;

  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  const hasMoreProducts = filteredProducts?.length > productsLimit;

  return (
    <>
      {filteredProducts?.length > 0 && (
        <ul
          className={`mx-auto grid grid-cols-2 gap-x-4 gap-y-8 px-4 xs:gap-x-5 ${
            enabledFilters ? 'md:grid-cols-2 lg:grid-cols-3' : 'md:grid-cols-3'
          } md:px-0`}
        >
          {filteredProducts.slice(0, productsLimit).map((product, index) => {
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = product.id || product.handle || index;
            return (
              <Fragment key={key}>
                {promoTile && (
                  <li key={`promo-tile-${key}`}>
                    <CollectionPromoTile tile={promoTile} />
                  </li>
                )}
                <li key={`collection-tile-${key}`}>
                  <ProductItem
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product.handle}
                    index={index}
                    isSearchPage={isSearchPage}
                    product={product}
                    swatchesMap={swatchesMap}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      )}

      {hasMoreProducts && (
        <div className="mt-10 flex justify-center" ref={loadMoreRef}>
          <button
            className={`${pagination?.buttonStyle}`}
            onClick={loadMoreProducts}
            type="button"
          >
            {pagination?.loadText}
          </button>
        </div>
      )}

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center text-center">
          <p>No products found matching these filters.</p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
