import { useEffect, useCallback, useRef, useState } from 'react';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapLineItem, trackKlaviyoEvent } from './utils';
import { useGlobalContext } from '../../contexts';

export function useDataLayerCart({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const { asPath } = useRouter();
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const {
    state: { cartOpen },
  } = useGlobalContext();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);

  const addToCartEvent = useCallback(
    (
      { lineItem, userProperties: _userProperties } = {
        lineItem: null,
        _userProperties: undefined,
      }
    ) => {
      if (!lineItem) return;

      trackKlaviyoEvent({
        email: _userProperties?.customer_email,
        event: 'Add To Cart',
        properties: mapLineItem(lineItem),
      });

      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'add_to_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode:
            lineItem.estimatedCost?.totalAmount?.currencyCode || 'USD',
          add: {
            actionField: { list },
            products: [mapLineItem(lineItem, 0)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const removeFromCartEvent = useCallback(
    (
      { lineItem, userProperties: _userProperties } = {
        lineItem: null,
        _userProperties: undefined,
      }
    ) => {
      if (!lineItem) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'remove_from_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode:
            lineItem.estimatedCost?.totalAmount?.currencyCode || 'USD',
          remove: {
            actionField: { list },
            products: [mapLineItem(lineItem)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const viewCartEvent = useCallback(
    (
      { cart: _cart, userProperties: _userProperties } = {
        _cart: null,
        _userProperties: undefined,
      }
    ) => {
      if (!_cart) return;
      const event = {
        event: 'view_cart',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        cart_total: _cart.estimatedCost?.totalAmount?.amount,
        ecommerce: {
          currencyCode: _cart.estimatedCost?.totalAmount?.currencyCode || 'USD',
          actionField: { list: 'Shopping Cart' },
          impressions: _cart.lines?.slice(0, 7).map(mapLineItem),
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  // Trigger 'dl_view_cart' event on cart page
  useEffect(() => {
    if (
      !asPath.startsWith('/cart') ||
      !cart ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    viewCartEvent({ cart, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, cart, !!userProperties]);

  // Trigger 'dl_view_cart' event when cart is opened
  useEffect(() => {
    if (!cartOpen || !userDataEventTriggered) return;
    viewCartEvent({ cart, userProperties });
  }, [cartOpen, userDataEventTriggered]);

  // Determine if a cart item was added, removed, or updated for events
  useEffect(() => {
    if (!cart) return;
    if (!previousCartItems) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      return;
    }
    if (!userDataEventTriggered) return;

    let isAdded = false;
    let isRemoved = false;
    let isIncreased = false; // Available cart event trigger
    let isDecreased = false; // Available cart event trigger
    let updatedCartItem = null;

    if (cartCount > previousCartCount) {
      updatedCartItem = cartItems.find((line, index) => {
        if (index === 0) {
          const currentAddedAt = line.attributes?.find(
            (attr) => attr.key === '_addedAt'
          )?.value;
          const prevAddedAt = previousCartItems[index]?.attributes?.find(
            (attr) => attr.key === '_addedAt'
          )?.value;
          if (currentAddedAt && currentAddedAt !== prevAddedAt) {
            isAdded = true;
            return true;
          }
        }
        if (line.quantity > previousCartItems[index]?.quantity) {
          isIncreased = true;
          return true;
        }
        return false;
      });
    } else if (cartCount < previousCartCount) {
      updatedCartItem = previousCartItems.find((prevLine, index) => {
        const prevAddedAt = prevLine.attributes?.find(
          (attr) => attr.key === '_addedAt'
        )?.value;
        const currentAddedAt = cartItems[index]?.attributes?.find(
          (attr) => attr.key === '_addedAt'
        )?.value;
        if (prevAddedAt && prevAddedAt !== currentAddedAt) {
          isRemoved = true;
          return true;
        }
        if (prevLine.quantity > cartItems[index]?.quantity) {
          isDecreased = true;
          return true;
        }
        return false;
      });
    }

    if (updatedCartItem) {
      if (isAdded) {
        addToCartEvent({ lineItem: updatedCartItem, userProperties });
      }
      if (isRemoved) {
        removeFromCartEvent({ lineItem: updatedCartItem, userProperties });
      }
    }

    setPreviousCartItems(cartItems);
    setPreviousCartCount(cartCount);
  }, [cart?.updatedAt, userDataEventTriggered]);
}
