import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const usePreserveScroll = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };

    const handleRouteChangeComplete = () => {
      if (router.pathname.split('/')[1] === 'collections') {
        const savedPosition = sessionStorage.getItem('scrollPosition');
        if (savedPosition) {
          window.scrollTo(0, parseInt(savedPosition, 10));
        }
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);
};
