export function Schema({ page }) {
  if (page?.handle !== 'wishlist') return null;

  return {
    label: 'Wishlist',
    key: 'wishlist',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Wishlist',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'text',
      },
    ],
  };
}
