export const fetchCollectionFromHandle = async (passedHandle) => {
  try {
    const handle = passedHandle?.handle || passedHandle;
    if (!handle) return null;
    const endpoint = `/json/collections/${handle}.json`;
    const response = await fetch(endpoint);
    return await response.json();
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const fetchCollectionsFromHandles = async (handles) => {
  try {
    if (!Array.isArray(handles) || !handles?.length) return [];
    const productPromises = handles.map(fetchCollectionFromHandle);
    return Promise.all(productPromises);
  } catch (error) {
    console.error(error.message);
    return [];
  }
};
