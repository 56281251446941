import { useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

export function FooterSocial() {
  const settings = useSettings();
  const { heading, links } = { ...settings?.footer?.social };

  return links?.length > 0 ? (
    <div className="flex flex-col text-current md:max-xl:flex-row md:max-xl:items-center md:max-xl:justify-center">
      {heading && (
        <h3 className="text-nav mb-3 md:max-xl:mb-0 md:max-xl:mr-4">
          {heading}
        </h3>
      )}

      <ul className="flex flex-wrap gap-3.5">
        {links.map(({ platform, url }, index) => {
          return (
            <li key={index}>
              <Link aria-label={platform} href={url} newTab>
                <Svg
                  className="w-6 text-current transition md:hover:opacity-80"
                  src={`/svgs/noprecache/social/${platform}.svg#${platform}`}
                  title={platform}
                  viewBox="0 0 24 24"
                />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

FooterSocial.displayName = 'FooterSocial';
