import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import {
  productTypeKey,
  sortAlphabetically,
  sortByCount,
  sortCustom,
  sortNumerically,
  updateFilterUrlParams,
} from './utils';

export function useCollectionFilters({
  enabledFilters = true,
  products = [],
  productsReady = true,
}) {
  const { isReady: routerIsReady, query } = useRouter();
  const settings = useSettings();
  const filtersSettings = { ...settings?.collection?.filters };

  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState([]);
  const [filtersMap, setFiltersMap] = useState({});

  const addFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key]
        ? [...updatedActiveFilters[key], value]
        : [value];
      setActiveFilters(updatedActiveFilters);
      updateFilterUrlParams({
        entriesToAdd: Object.entries(updatedActiveFilters),
      });
    },
    [activeFilters]
  );

  const removeFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key].filter(
        (item) => item !== value
      );
      if (updatedActiveFilters[key]?.length === 0)
        delete updatedActiveFilters[key];
      setActiveFilters(updatedActiveFilters);

      if (activeFilters[key]?.length === 1) {
        updateFilterUrlParams({
          keysToRemove: [key],
        });
      } else {
        updateFilterUrlParams({
          entriesToAdd: Object.entries(updatedActiveFilters),
        });
      }
    },
    [activeFilters]
  );

  const clearFilters = useCallback(() => {
    setActiveFilters({});
    updateFilterUrlParams({ keysToRemove: Object.keys(activeFilters) });
  }, [activeFilters]);

  const initialFiltersData = useMemo(() => {
    if (!enabledFilters || !filtersSettings.filters?.length) return null;

    // array of tag and option filter names set in customizer
    const tagFilters = [];
    const optionFilters = [];
    // set up initial filters map
    const _filtersMap = filtersSettings.filters.reduce(
      (
        acc,
        {
          customOrder,
          defaultOpen,
          defaultOpenMobile,
          isColor,
          label,
          name,
          orderValuesBy,
          ranges: priceRanges,
          _template: source,
        }
      ) => {
        // ignore option and tag filters with no name
        if ((source === 'option' || source === 'tag') && !name) return acc;

        // filter name key
        let filterName;
        if (source === 'productType') {
          filterName = productTypeKey;
        } else if (source === 'price') {
          filterName = 'price';
        } else if (source === 'tag') {
          const _name = name?.trim();
          filterName = `${source}.${_name}`;
          tagFilters.push(_name);
        } else if (source === 'option') {
          const _name = name?.trim();
          filterName = `${source}.${_name}`;
          optionFilters.push(_name);
        }
        const filter = {
          name: filterName,
          label,
          isColor: isColor || false,
          defaultOpen: defaultOpen || false,
          defaultOpenMobile: defaultOpenMobile || false,
          orderValuesBy,
          customOrder,
          ...(source === 'price' ? { priceRanges } : null),
          source,
          values: [],
          valuesMap: {},
        };
        return { ...acc, [filter.name]: filter };
      },
      {}
    );

    return {
      tagFilters,
      optionFilters,
      filtersMap: _filtersMap,
      colorGroups: filtersSettings.colorGroups || [],
    };
  }, [enabledFilters, filtersSettings.filters, filtersSettings.colorGroups]);

  // sets up filters and options on collection load
  useEffect(() => {
    if (
      !enabledFilters ||
      !initialFiltersData ||
      !products.length ||
      !productsReady
    )
      return;
    const {
      colorGroups,
      tagFilters,
      optionFilters,
      filtersMap: _filtersMap,
    } = initialFiltersData;

    products.forEach(({ optionsMap, priceRange, productType, tags }) => {
      // product type options
      if (_filtersMap[productTypeKey] && productType) {
        _filtersMap[productTypeKey].valuesMap = {
          ..._filtersMap[productTypeKey].valuesMap,
          [productType]: {
            value: productType,
            count:
              (_filtersMap[productTypeKey].valuesMap[productType]?.count || 0) +
              1,
          },
        };
      }
      // price range options
      if (_filtersMap.price && priceRange?.min) {
        const price = parseFloat(priceRange.min);
        const range = _filtersMap.price.priceRanges?.find(({ min, max }) => {
          return price >= (min || 0) && price < (max || Infinity);
        })?.label;
        _filtersMap.price.valuesMap = {
          ..._filtersMap.price.valuesMap,
          [range]: {
            value: range,
            count: (_filtersMap.price.valuesMap[range]?.count || 0) + 1,
          },
        };
      }
      // tag filter options
      if (tagFilters?.length && tags?.length) {
        tags.forEach((tag) => {
          const [_key, _value] = tag.split('::');
          const key = _key.trim();
          const value = _value?.trim();
          if (value && tagFilters.includes(key)) {
            _filtersMap[`tag.${key}`].valuesMap = {
              ..._filtersMap[`tag.${key}`].valuesMap,
              [value]: {
                value,
                count:
                  (_filtersMap[`tag.${key}`].valuesMap[value]?.count || 0) + 1,
              },
            };
          }
        });
      }
      // option filter options
      Object.entries({ ...optionsMap }).forEach(([_key, values]) => {
        const key = _key.trim();
        if (optionFilters.includes(key)) {
          _filtersMap[`option.${key}`].valuesMap = {
            ..._filtersMap[`option.${key}`].valuesMap,
            ...values.reduce((acc, value) => {
              if (key === 'Color') {
                const colorGroup = colorGroups.find((group) =>
                  group?.colors?.some((color) => value.indexOf(color) > -1)
                );
                if (colorGroup) {
                  const { color, group } = colorGroup;
                  return {
                    ...acc,
                    [group]: {
                      swatchColor: color,
                      value: group,
                      count:
                        (_filtersMap[`option.${key}`].valuesMap[group]?.count ||
                          0) + 1,
                    },
                  };
                }
                return acc;
              }
              return {
                ...acc,
                [value]: {
                  value,
                  count:
                    (_filtersMap[`option.${key}`].valuesMap[value]?.count ||
                      0) + 1,
                },
              };
            }, {}),
          };
        }
      });
    });
    // sort options
    Object.values(_filtersMap).forEach((filter) => {
      const values = Object.values(filter.valuesMap);
      if (filter.source === 'price') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.priceRanges?.map(({ label }) => label) || [],
        });
      } else if (filter.orderValuesBy === 'priceRange') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.priceRanges.map(({ label }) => label),
        });
      } else if (filter.orderValuesBy === 'alphabet') {
        _filtersMap[filter.name].values = sortAlphabetically({ values });
      } else if (filter.orderValuesBy === 'number') {
        _filtersMap[filter.name].values = sortNumerically({ values });
      } else if (filter.orderValuesBy === 'count') {
        _filtersMap[filter.name].values = sortByCount({ values });
      } else if (filter.orderValuesBy === 'custom') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.customOrder,
        });
      } else {
        _filtersMap[filter.name].values = values;
      }
      delete _filtersMap[filter.name].orderValuesBy;
      delete _filtersMap[filter.name].customOrder;
    });

    setFilters(Object.values(_filtersMap));
    setFiltersMap(_filtersMap);
  }, [enabledFilters, initialFiltersData, products, productsReady]);

  // sets filters on page load
  useEffect(() => {
    if (!routerIsReady || !Object.keys({ ...filtersMap }).length) return;
    const filtersFromParams = Object.entries({ ...query }).reduce(
      (acc, [key, value]) => {
        if (key === 'handle') return acc;
        const values = value.split(',');
        const valuesMap = filtersMap[key]?.valuesMap;
        values.forEach((valuesItem) => {
          if (!valuesMap?.[valuesItem]) return;
          if (!acc[key]) acc[key] = [];
          acc[key] = [...acc[key], valuesItem];
        });
        return acc;
      },
      {}
    );
    setActiveFilters(filtersFromParams);
  }, [routerIsReady, filtersMap]);

  // clear filters state on unmount
  useEffect(() => {
    return () => {
      setActiveFilters({});
      setFilters([]);
      setFiltersMap({});
    };
  }, []);

  return {
    state: {
      activeFilters,
      filters,
      filtersMap,
    },
    actions: {
      addFilter,
      removeFilter,
      clearFilters,
    },
  };
}
