export function Schema() {
  return {
    category: 'Media',
    label: 'YouTube Video Embed',
    key: 'video-embed',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/video-section-preview-image.jpg?v=1681791343',
    fields: [
      {
        label: 'Media Settings',
        name: 'media',
        component: 'group',
        fields: [
          {
            label: 'Video Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Video Id',
            description:
              'YouTube video link ID, Ex. youtube share url: "http://youtu.be/FBdX7mPqpnk", the ID would be: FBdX7mPqpnk',
            name: 'src',
            component: 'text',
          },
        ],
        defaultValue: {
          title: 'Video Content',
          src: 'FBdX7mPqpnk',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Max width, horizontal padding',
        fields: [
          {
            label: 'Max Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
          {
            label: 'Enable Vertical Padding',
            name: 'enableYPadding',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Horizontal Padding',
            name: 'enableXPadding',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          maxWidth: 'max-w-full',
          enableYPadding: false,
          enableXPadding: false,
        },
      },
    ],
  };
}
