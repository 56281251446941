import { useMemo } from 'react';

import { Image, Link, Spinner, Svg } from '@snippets';
import { QuantitySelector } from './QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { variant } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const url = useMemo(() => {
    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  return (
    <div className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4 ">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        {image?.src && (
          <Image
            alt={variant.product.title}
            className="bg-offWhite"
            crop="center"
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            href={url}
            onClick={closeCart}
          >
            <h3 className="text-title-h5">{variant.product.title}</h3>
          </Link>

          {variant.title !== 'Default Title' && (
            <p className="text-body-sm text-mediumGray">{variant.title}</p>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute top-0 right-0 w-3"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="flex items-center justify-between gap-3">
          <QuantitySelector
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            isUpdatingItem={isUpdatingItem}
            item={item}
          />

          <div className="flex flex-1 flex-wrap justify-end gap-x-2">
            {compareAtPrice && (
              <p className="text-body text-mediumGray line-through">{compareAtPrice}</p>
            )}
            <p className={`${compareAtPrice ? 'text-accent2' : 'text-text'} text-body`}>{price}</p>
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
