import { useMemo, useState } from 'react';

import { Svg } from '@snippets';
import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMenuSidebar } from '../CollectionMenuSidebar';

export function CollectionMobileFilters({
  activeFilters,
  addFilter,
  clearFilters,
  collectionCount,
  filters,
  filtersMap,
  optionsMaxCount,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const totalFilters = useMemo(() => {
    return Object.values(activeFilters || {}).reduce((acc, filterValues) => {
      return acc + filterValues.length;
    }, 0);
  }, [activeFilters]);

  return (
    <>
      <button
        aria-label="Open filters sidebar"
        className="relative flex h-14 w-full items-center justify-between border-y border-lightGray pl-4 pr-2.5 text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <div className="flex flex-1 items-center gap-2 overflow-hidden xs:gap-3">
          <p className="text-title-h5">Filters</p>

          {totalFilters > 0 && (
            <p className="text-body-sm truncate pt-[1px] text-mediumGray">
              {totalFilters} Selected
            </p>
          )}
        </div>

        <Svg
          className="w-5 text-black"
          src="/svgs/chevron-right.svg#chevron-right"
          title="Arrow Right"
          viewBox="0 0 24 24"
        />
      </button>

      <CollectionMenuSidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Filters"
      >
        <div className="sticky top-0 z-[1] border-b border-lightGray bg-background p-4 pt-5">
          <div className="mb-4 flex justify-between gap-2">
            <p className="text-title-h5">
              Filters Summary{' '}
              <span className="text-body">
                {totalFilters ? `(${totalFilters})` : ''}
              </span>
            </p>

            {totalFilters > 0 && (
              <button
                className="text-link-cta underline underline-offset-4"
                onClick={clearFilters}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="scrollbar-hide max-h-[4.5rem] min-h-[2rem] overflow-y-auto">
            {totalFilters ? (
              <CollectionFiltersSummary
                activeFilters={activeFilters}
                filtersMap={filtersMap}
                removeFilter={removeFilter}
              />
            ) : (
              <p className="leading-[2rem] text-mediumGray">
                No filters selected yet
              </p>
            )}
          </div>
        </div>

        <ul>
          {filters.map((filter, index) => {
            if (
              !filter.values.length ||
              (filter.values.length === 1 &&
                filter.values[0].count === collectionCount)
            )
              return null;

            return (
              <li key={index}>
                <CollectionDropdownFilter
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  defaultOpen={filter.defaultOpenMobile}
                  filter={filter}
                  optionsMaxCount={optionsMaxCount}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                  isMobile
                />
              </li>
            );
          })}
        </ul>
      </CollectionMenuSidebar>
    </>
  );
}

CollectionMobileFilters.displayName = 'CollectionMobileFilters';
