import { useReviewsIo } from '@hooks';
import { ReviewStars } from '../ReviewStars';

export function ProductStars({ product, isPdp }) {
  const { average, count, loaded } = useReviewsIo(product);

  const size = isPdp ? 'large' : 'small';

  if (!loaded) return null;

  return (
    <div className="flex min-h-[1rem] flex-wrap items-center gap-1">
      {loaded && (
        <>
          <ReviewStars rating={average} size={size} />

          <p
            className={`${
              isPdp ? 'text-body-sm' : 'text-2xs'
            } text-mediumDarkGray`}
          >
            ({count} {count === 1 ? 'Review' : 'Reviews'})
          </p>
        </>
      )}
    </div>
  );
}

ProductStars.displayName = 'ProductStars';
