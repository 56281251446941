import { useRef } from 'react';

import { Badges, Image, Picture, Spinner } from '@snippets';
import { ProductItemVideo } from './ProductItemVideo';
import { useProductItemMedia } from './useProductItemMedia';

function ProductItemPicture({ alt, src }) {
  return (
    <Picture
      alt={alt}
      aboveTheFold
      images={[
        {
          src,
          width: 350,
          ratio: 2 / 3,
        },
        {
          src,
          width: 350,
          scale: 2,
          ratio: 2 / 3,
        },
      ]}
    />
  );
}

export function ProductItemMedia({ selectedProduct, selectedVariant }) {
  const hoverVideoRef = useRef(null);

  const { primaryMedia, hoverMedia } = useProductItemMedia({
    selectedProduct,
    selectedVariant,
  });

  return (
    <div
      className="group/media relative aspect-[var(--product-image-aspect-ratio)] bg-offWhite"
      onMouseEnter={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.play();
      }}
      onMouseLeave={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.pause();
      }}
    >
      {/* media w/o hover element */}
      {primaryMedia &&
        !hoverMedia &&
        (primaryMedia.mediaContentType === 'VIDEO' ? (
          <ProductItemVideo autoPlay media={primaryMedia} />
        ) : (
          <ProductItemPicture
            src={primaryMedia.image.src}
            alt={selectedProduct?.title}
          />
        ))}

      {/* media w/ hover element */}
      {primaryMedia && hoverMedia && (
        <>
          <div className="h-full w-full opacity-100 transition duration-300 md:group-hover/media:opacity-0">
            {primaryMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo autoPlay media={primaryMedia} />
            ) : (
              <ProductItemPicture
                src={primaryMedia.image.src}
                alt={selectedProduct?.title}
              />
            )}
          </div>

          <div className="absolute inset-0 hidden h-full w-full opacity-0 transition duration-300 md:block md:group-hover/media:opacity-100">
            {hoverMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo
                autoPlay={false}
                media={hoverMedia}
                ref={hoverVideoRef}
              />
            ) : (
              <ProductItemPicture
                src={hoverMedia.image.src}
                alt={selectedProduct?.title}
              />
            )}
          </div>
        </>
      )}

      {/* loader */}
      {!primaryMedia && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray">
          <Spinner />
        </div>
      )}

      <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-3 xl:p-4">
        <Badges
          className="max-xl:text-label-sm gap-2 xs:gap-2 [&_div]:max-xl:px-1.5 [&_div]:max-xl:py-0.5"
          isDraft={selectedProduct?.status === 'DRAFT'}
          tags={selectedVariant?.product?.tags}
        />
      </div>
    </div>
  );
}

ProductItemMedia.displayName = 'ProductItemMedia';
