import { useMemo } from 'react';
import {
  useSettings,
  useProductRecommendationsByHandle,
  useCartItems,
} from '@backpackjs/storefront';
import { CartUpsellSlider } from './CartUpsellSlider';

export function CartUpsellMain({ closeCart }) {
  const settings = useSettings();
  const {
    enabled,
    customUpsell,
    message,
    products,
    showUpsellOnEmpty,
    defaultProduct,
  } = {
    ...settings?.cart?.upsell,
  };

  const cartItems = useCartItems();

  const defaultRecs = useProductRecommendationsByHandle({
    handle: defaultProduct?.handle,
    limit: 6,
  });

  const { recommendations } = useProductRecommendationsByHandle({
    handle: cartItems?.[0]?.variant?.product?.handle,
    limit: 6,
  });

  const recs =
    cartItems?.length > 0 ? recommendations : defaultRecs?.recommendations;

  const upsellRecommendationsNotInCart = useMemo(() => {
    if (!recs?.length > 0 || customUpsell) return null;
    return recs?.filter(
      (rec) =>
        !cartItems?.some(
          (cartItem) => cartItem?.variant?.product?.handle === rec.handle
        )
    );
  }, [recs?.length, customUpsell, cartItems]);

  const upsellProductsNotInCart = useMemo(() => {
    if (!products?.length > 0 || !customUpsell) return null;
    return products?.filter(
      ({ product }) =>
        !cartItems?.some(
          (cartItem) => cartItem?.variant?.product?.handle === product.handle
        )
    );
  }, [products?.length, customUpsell, cartItems]);

  const hasProducts =
    upsellProductsNotInCart?.length > 0 ||
    upsellRecommendationsNotInCart?.length > 0;

  const showUpsell =
    (enabled && hasProducts && showUpsellOnEmpty) ||
    (enabled && hasProducts && !showUpsellOnEmpty && cartItems?.length > 0);

  return showUpsell ? (
    <div className="flex flex-col gap-4 border-t border-t-border p-4">
      <h3 className="text-title-h6 text-center">{message}</h3>

      <CartUpsellSlider
        products={upsellProductsNotInCart || upsellRecommendationsNotInCart}
        closeCart={closeCart}
      />
    </div>
  ) : null;
}

CartUpsellMain.displayName = 'CartUpsellMain';
