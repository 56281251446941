import { useCallback, useEffect, useState } from 'react';
import { useCustomer } from '@backpackjs/storefront';

import { validateId } from '@utilities';

const isBrowser = typeof window !== 'undefined';

export function InfluenceFloatingWidget() {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [customerIsReady, setCustomerIsReady] = useState(false);
  const [customerDigest, setCustomerDigest] = useState(null);

  const customer = useCustomer();

  const getHmacKey = useCallback(async () => {
    const id = validateId(customer?.id)?.split('/').pop();

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId: id,
        customerEmail: customer?.email,
      }),
    };

    const response = await fetch('/api/influence-io-hmac', options);
    const data = await response.json();
    return data;
  }, [customer]);

  useEffect(() => {
    const loadWidget = async () => {
      if (isBrowser && customer !== undefined) {
        if (customer?.id) {
          const { hmac } = await getHmacKey();
          setCustomerDigest(hmac);
        }
        setTimeout(() => {
          setCustomerIsReady(true);
        }, 1000);
      }
    };
    loadWidget();
  }, [customer]);

  /* needs to load a few seconds after customer is loaded */
  useEffect(() => {
    if (customerIsReady && !scriptIsLoaded) {
      const script = document.createElement('script');
      script.onload = setScriptIsLoaded(true);
      script.id = 'influence-floating-widget-script';
      script.src = 'https://widgets.influence.io/dist/floating/main.js';
      document.head.appendChild(script);
    }

    return () => {
      document.getElementById('influence-floating-widget-script')?.remove();
    };
  }, [customerIsReady, scriptIsLoaded]);

  useEffect(() => {
    // reset floating widget when customer data updates
    if (scriptIsLoaded) {
      document
        .querySelectorAll('.LoyaltyWidget-prefix')
        .forEach((el) => el.remove());
      setTimeout(() => {
        window.influenceioFloatingWidget();
      }, 2000);
    }
  }, [customer, scriptIsLoaded]);

  return (
    <>
      <div
        className="influenceio-shopify-init"
        data-shop="booty-by-brabants.myshopify.com"
        data-customer-email={customer?.email || null}
        data-customer-id={validateId(customer?.id)?.split('/').pop() || null}
        data-customer-digest={customerDigest || null}
      />
      <style>
        {`
          .LoyaltyWidget-prefix {
            z-index: 60;
            position: relative;
          }
        `}
      </style>
    </>
  );
}

InfluenceFloatingWidget.displayName = 'InfluenceFloatingWidget';
