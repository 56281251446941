import { useMemo } from 'react';

export function useVariantPrices({ variant }) {
  const { id, priceV2, compareAtPriceV2 } = { ...variant };

  return useMemo(() => {
    if (!priceV2?.amount) {
      return { price: null, compareAtPrice: null };
    }

    const amount = parseFloat(priceV2.amount);
    const compareAtAmount = parseFloat(compareAtPriceV2?.amount || '0');

    const percentageAmount =
      compareAtAmount > amount
        ? Math.floor(100 - (100 * amount) / compareAtAmount)
        : '0';

    return {
      price: `$${amount.toFixed(2)}`,
      compareAtPrice:
        compareAtAmount > amount ? `$${compareAtAmount.toFixed(2)}` : null,
      percentageSaved: percentageAmount,
    };
  }, [id]);
}
