import { useState } from 'react';

import { Markdown, Svg } from '@snippets';

export function ProductDetailAccordion({
  accordion,
  headerBgColor,
  headerTextColor,
}) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex h-14 w-full items-center justify-between gap-x-4 p-4"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <h2 className="text-title-h5">{header}</h2>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`[&_p]:text-body [&_ul>li]:text-body [&_ul]:text-body [&_ol]:text-body [&_span]:!text-body px-4 pt-4 [&_blockquote]:!pl-4 [&_h1]:mb-3 [&_h1]:text-base [&_h2]:mb-3 [&_h2]:text-base [&_h3]:mb-3 [&_h3]:text-base [&_h4]:mb-3 [&_h4]:text-base [&_h5]:mb-3 [&_h5]:text-base [&_h6]:mb-3 [&_h6]:text-base [&_ol]:mb-3 [&_ol]:!pl-4 [&_p]:mb-3 [&_ul]:mb-3 [&_ul]:!pl-4  ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';
