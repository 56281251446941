import React from 'react';
import { useWishlistContext } from '@contexts';

import { ProductItem } from '@snippets';
import { Schema } from './Wishlist.schema';

export function Wishlist({ cms }) {
  const { heading, description } = cms;
  const {
    state: { wishlist },
  } = useWishlistContext();

  return (
    <div className="px-contained py-contained-2x">
      <div className="mb-10 text-center">
        <h1>{heading}</h1>
        {description && <p className="mt-5">{description}</p>}
      </div>
      {wishlist?.length ? (
        <ul className="mx-auto grid grid-cols-2 gap-x-3 gap-y-5 xs:gap-x-5 xs:gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          {wishlist.map(({ epi, du }, index) => {
            const handle = du.split('/').pop()?.split('?variant')[0];
            return (
              <li key={epi}>
                <ProductItem handle={handle} index={index} />
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-center">Your wishlist is empty.</p>
      )}
    </div>
  );
}

Wishlist.displayName = 'Wishlist';
Wishlist.Schema = Schema;
