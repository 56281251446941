import { Schema } from './VideoEmbed.schema';

export function VideoEmbed({ cms }) {
  const { media, section } = cms;
  const { title, src } = {
    ...media,
  };
  const { maxWidth, enableYPadding, enableXPadding } = { ...section };

  return (
    <div
      className={`${enableYPadding ? 'py-4 md:py-6 lg:py-8' : ''} ${
        enableXPadding ? 'px-contained' : ''
      }`}
    >
      <div className={`relative mx-auto aspect-[16/9] bg-offWhite ${maxWidth}`}>
        {src && (
          <iframe
            width="100%"
            style={{ aspectRatio: 16 / 9 }}
            src={`https://www.youtube.com/embed/${src}`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        )}
      </div>
    </div>
  );
}

VideoEmbed.displayName = 'VideoEmbed';
VideoEmbed.Schema = Schema;
