import Script from 'next/script';

import { useDataLayerAccount } from './useDataLayerAccount';
import { useDataLayerCart } from './useDataLayerCart';
import { useDataLayerCollection } from './useDataLayerCollection';
import { useDataLayerCustomer } from './useDataLayerCustomer';
import { useDataLayerInit } from './useDataLayerInit';
import { useDataLayerProduct } from './useDataLayerProduct';
import { useDataLayerSearch } from './useDataLayerSearch';

// Important, make sure to either:
// * add env var NEXT_PUBLIC_SITE_TITLE;
// * or, harcode site title as the "brand" values in the "utils.js" file

const DEBUG = true;

export function DataLayer({ pageTitle, product, template }) {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
    pageTitle,
    template,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    product,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  return (
    <>
      <Script
        id="gtag-script"
        type="text/javascript"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-VN1M5TY0Q6"
      />

      <Script
        id="gtag-config"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-VN1M5TY0Q6');
          `,
        }}
      />
    </>
  );
}

DataLayer.displayName = 'DataLayer';
