import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapProductItem, productItemVariant } from './utils';

export function useDataLayerSearch({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const { asPath } = useRouter();

  const [searchPageResults, setSearchPageResults] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [clickedSearchResultsItem, setClickedSearchResultsItem] =
    useState(null);

  const viewSearchResultsEvent = useCallback(
    (
      { results, userProperties: _userProperties } = {
        results: [],
        _userProperties: undefined,
      }
    ) => {
      if (!results?.length) return;
      const event = {
        event: 'view_search_results',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: results[0].variants?.[0]?.currencyCode || 'USD',
          actionField: { list: 'search results' },
          impressions: results.slice(0, 7).map(mapProductItem),
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  const clickSearchResultsItemEvent = useCallback(
    (
      { userProperties: _userProperties, variant } = {
        _userProperties: undefined,
        variant: null,
      }
    ) => {
      if (!variant) return;
      const event = {
        event: 'select_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: 'USD',
          click: {
            actionField: { list: 'search results', action: 'click' },
            products: [productItemVariant(variant, variant.index)],
          },
        },
      };

      window.gtag('event', event.event, event);
      if (DEBUG) console.log(`DataLayer:${event.event}`, event);
    },
    []
  );

  // Subscribe to PubSub topics for 'dl_view_search_results' and 'dl_select_item' events
  useEffect(() => {
    const viewSearchPageResults = PubSub.subscribe(
      'VIEW_SEARCH_PAGE_RESULTS',
      async (event, results) => {
        setSearchPageResults(results);
      }
    );
    const viewSearchResults = PubSub.subscribe(
      'VIEW_SEARCH_RESULTS',
      async (event, results) => {
        setSearchResults(results);
      }
    );
    const clickSearchItem = PubSub.subscribe(
      'CLICK_SEARCH_ITEM',
      async (event, variant) => {
        setClickedSearchResultsItem(variant);
      }
    );
    return () => {
      if (viewSearchPageResults) {
        PubSub.unsubscribe(viewSearchPageResults);
      }
      if (viewSearchResults) {
        PubSub.unsubscribe(viewSearchResults);
      }
      if (clickSearchItem) {
        PubSub.unsubscribe(clickSearchItem);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and 'dl_view_search_results' events after
  // new sidebar search results and base data is ready

  useEffect(() => {
    if (
      !asPath.startsWith('/pages/search') ||
      !searchPageResults?.length ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    viewSearchResultsEvent({ results: searchPageResults, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [
    asPath,
    searchPageResults?.map((p) => p.handle).join(''),
    !!userProperties,
  ]);

  // Trigger 'dl_user_data' and 'dl_view_search_results' events after
  // new search page results and base data is ready
  useEffect(() => {
    if (!searchResults || !userDataEventTriggered) return;
    viewSearchResultsEvent({ results: searchResults, userProperties });
  }, [searchResults, userDataEventTriggered]);

  // Trigger 'dl_select_item' after clicked search item and user event
  useEffect(() => {
    if (!clickedSearchResultsItem || !userDataEventTriggered) return;
    clickSearchResultsItemEvent({
      userProperties,
      variant: clickedSearchResultsItem,
    });
  }, [clickedSearchResultsItem, userDataEventTriggered]);
}
