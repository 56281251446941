import { useCallback, useMemo, useState } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';

import { Spinner } from '@snippets';
import { useGlobalContext } from '@contexts';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  value,
  isLast,
}) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const [isAdding, setIsAdding] = useState(false);

  const variantToAdd = useMemo(() => {
    return selectedProduct.variants.find((variant) => {
      const color = variant.selectedOptionsMap.Color;
      return (
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut =
    inventoryFetched && !variantInventory?.availableForSale;

  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryFetched, isAdding]);

  const disabled = !variantToAdd || variantIsSoldOut;

  return (
    <button
      aria-label={value}
      className={`group/option flex h-full w-full items-center justify-center whitespace-nowrap border-r border-black text-center text-sm transition ${
        isLast ? 'border-none' : ''
      }  ${
        disabled
          ? 'cursor-not-allowed text-mediumGray'
          : 'cursor-pointer hover:bg-black hover:text-white'
      }`}
      disabled={disabled}
      onClick={handleAddToCart}
      type="button"
    >
      {isAdding ? (
        <div className="text-mediumGray group-hover/option:text-white">
          <Spinner />
        </div>
      ) : (
        value
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
