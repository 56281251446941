import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  Cart,
  DataLayer,
  Footer,
  Header,
  InfluenceFloatingWidget,
  Modal,
  Overlay,
  SchemaMarkup,
  Search,
  StorefrontHead,
} from '@snippets';
import { GlobalContextProvider, WishlistContextProvider } from '@contexts';
import {
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
} from '@hooks';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  useShopifyAnalytics({ collection: props.collection, product: props.product });

  return (
    <GlobalContextProvider>
      <WishlistContextProvider>
        <StorefrontHead />

        <DataLayer {...props} />

        <SchemaMarkup {...props} />

        <Header promobarDisabled={promobarDisabled} />

        <main
          className={`flex flex-col
        ${
          promobarDisabled
            ? 'pt-[var(--header-height)]'
            : 'pt-[calc(var(--header-height)+var(--promobar-height))]'
        }`}
        >
          <ContentForLayout {...props} />
        </main>

        <Footer />

        <Overlay />

        <Cart />

        <Search />

        <Modal />

        <InfluenceFloatingWidget />
      </WishlistContextProvider>
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;
