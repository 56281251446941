import { useCallback, useState } from 'react';
import { useCartRemoveItem, useCartUpdateItem } from '@backpackjs/storefront';

export const useCartItem = ({ item }) => {
  const { id, quantity } = { ...item };
  const { cartRemoveItem } = useCartRemoveItem();
  const { cartUpdateItem } = useCartUpdateItem();
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [isRemovingItem, setIsRemovingItem] = useState(false);

  const handleDecrement = useCallback(async () => {
    if (quantity > 1) {
      setIsUpdatingItem(true);
      await cartUpdateItem({ lineId: id, quantity: quantity - 1 });
      setIsUpdatingItem(false);
    } else {
      setIsRemovingItem(true);
      await cartRemoveItem({ lineId: id });
    }
  }, [id, quantity]);

  const handleIncrement = useCallback(async () => {
    setIsUpdatingItem(true);
    await cartUpdateItem({ lineId: id, quantity: quantity + 1 });
    setIsUpdatingItem(false);
  }, [id, quantity]);

  const handleRemove = useCallback(async () => {
    setIsRemovingItem(true);
    await cartRemoveItem({ lineId: id });
    setIsRemovingItem(false);
  }, [id]);

  return {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  };
};
