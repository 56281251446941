import { useEffect, useState } from 'react';

import { Link, Spinner } from '@snippets';
import { usePredictiveSearch, useSearchProductResults } from '@hooks';
import { useGlobalContext } from '@contexts';
import { SearchHeader } from './SearchHeader';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';

export function Search() {
  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { loading, productResults } = useSearchProductResults({
    term,
    mounted: searchOpen,
  });
  const { collectionResults } = usePredictiveSearch({
    term,
    mounted: searchOpen,
  });

  const hasProductResults = productResults?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults;

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-background transition md:max-w-[23.5rem] ${
        searchOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
    >
      {searchOpen && (
        <>
          <SearchHeader closeSearch={closeSearch} />

          <SearchInput
            closeSearch={closeSearch}
            rawTerm={rawTerm}
            searchOpen={searchOpen}
            setRawTerm={setRawTerm}
          />

          {loading ? (
            <div className="relative flex flex-1">
              <Spinner
                width="32"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          ) : (
            <>
              {hasResults ? (
                <SearchResults
                  closeSearch={closeSearch}
                  collectionResults={collectionResults}
                  productResults={productResults}
                />
              ) : (
                <SearchSuggestions term={term} setRawTerm={setRawTerm} />
              )}

              {hasProductResults && (
                <div className="border-t border-t-border p-4">
                  <Link
                    aria-label="See all search results"
                    className="btn-primary w-full"
                    href={{ pathname: '/pages/search', query: { term } }}
                    onClick={closeSearch}
                  >
                    See All {productResults.length} Results
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
    </aside>
  );
}

Search.displayName = 'Search';
