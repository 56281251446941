import { useCallback, useMemo } from 'react';
import _xorBy from 'lodash.xorby';

import { useWishlistContext } from '@contexts';
import { useWishlist } from '@hooks';
import { Svg } from '@snippets';

export function Favorite({ selectedVariant, isPdp }) {
  const { updateWishlist } = useWishlist();
  const {
    state: { wishlist, wishlistId: lid, wishlistEmail },
    actions: { updateWishlist: setWishlist },
  } = useWishlistContext();

  const isWishlisted = useMemo(() => {
    const _selectedResourceId =
      parseInt(selectedVariant?.legacyResourceId, 10) || null;
    return wishlist?.find(({ epi }) => epi === _selectedResourceId);
  }, [wishlist, selectedVariant]);

  const toggleWishlist = useCallback(
    async (event) => {
      event.preventDefault();

      const item = {
        epi: parseInt(selectedVariant.legacyResourceId, 10),
        empi: parseInt(selectedVariant.product.legacyResourceId, 10),
        du: `https://${process.env.SHOPIFY_DOMAIN}/products/${selectedVariant.product.handle}`,
      };

      const updatedWishlist = _xorBy(wishlist, [item], 'epi');
      setWishlist(updatedWishlist);

      if (wishlistEmail) {
        await updateWishlist({
          params: {
            lid,
            email: wishlistEmail,
            add: isWishlisted ? [] : [item],
            remove: isWishlisted ? [item] : [],
          },
        });
      }
    },
    [selectedVariant, wishlist, isWishlisted]
  );

  return (
    <button
      aria-label={
        isWishlisted
          ? `Add ${selectedVariant?.product?.title || ''} to favorites.`
          : `Remove ${selectedVariant?.product?.title || ''} from favorites.`
      }
      className="absolute right-1 top-1 flex items-center p-2"
      onClick={toggleWishlist}
      type="submit"
    >
      {isWishlisted ? (
        <Svg
          className="w-4 fill-black"
          viewBox="0 0 72 72"
          alt="Favorite"
          src="/svgs/booty-full.svg#booty-full"
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      ) : (
        <Svg
          className="w-4"
          viewBox="0 0 72 72"
          alt="Favorite"
          src="/svgs/booty-empty.svg#booty-empty"
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      )}
    </button>
  );
}

Favorite.displayName = 'Favorite';
