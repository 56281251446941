import PropTypes from 'prop-types';

import { Spinner, BackInStockModal } from '@snippets';
import { useAddToCart } from '@hooks';

export function AddToCart({
  addToCartText,
  className,
  isPdp,
  quantity,
  selectedVariant,
  isQuickShop,
}) {
  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    addToCartText,
    quantity,
    selectedVariant,
  });

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  return (
    <div>
      <button
        aria-label={buttonText}
        className={`relative w-full ${cursorClass} ${
          isNotifyMe ? 'btn-inverse-dark' : className
        }`}
        disabled={(isSoldOut && !isNotifyMe) || isLoading}
        onClick={() => {
          if (isNotifyMe) {
            handleNotifyMe(
              <BackInStockModal selectedVariant={selectedVariant} />
            );
          } else {
            handleAddToCart();
          }
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner />
        ) : (
          <p>{isQuickShop && isNotifyMe ? 'Notify Me' : buttonText}</p>
        )}
      </button>

      {isPdp && subtext && (
        <p className="mt-1 text-center text-xs">{subtext}</p>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.defaultProps = {
  addToCartText: '',
  className: '',
  isPdp: false,
  quantity: 1,
  selectedVariant: null,
};
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  quantity: PropTypes.number,
  selectedVariant: PropTypes.object,
};
