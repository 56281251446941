import { useMemo } from 'react';
import {
  useCartCount,
  useCartTotals,
  useSettings,
} from '@backpackjs/storefront';

export function FreeShippingMeter() {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const settings = useSettings();
  const {
    enabled,
    minCartSpend,
    progressBarColor,
    progressMessage,
    qualifiedMessage,
  } = { ...settings?.cart?.freeShipping };
  const showMeter = enabled && cartCount > 0 && minCartSpend > 0;

  const progress = useMemo(() => {
    if (!showMeter) return null;

    const total = parseFloat(cartTotals.total);
    const fraction = total / minCartSpend;
    const remainder = minCartSpend - total;

    if (fraction >= 1) {
      return {
        percent: 100,
        message: qualifiedMessage,
      };
    }

    return {
      percent: fraction * 100,
      message: progressMessage?.replace(
        '{{amount}}',
        `$${remainder.toFixed(2)}`
      ),
    };
  }, [
    cartTotals.total,
    minCartSpend,
    progressMessage,
    qualifiedMessage,
    showMeter,
  ]);

  return showMeter ? (
    <div className="border-none border-b-lightGray bg-offWhite p-4">
      <p className="mb-2 text-center text-label">{progress.message}</p>

      <div className="h-1.5 w-full overflow-hidden rounded bg-white">
        <div
          className="h-full w-full origin-left transition"
          style={{
            transform: `scaleX(${progress.percent}%)`,
            backgroundColor: progressBarColor,
          }}
        />
      </div>
    </div>
  ) : null;
}

FreeShippingMeter.displayName = 'FreeShippingMeter';
