import { useProductByHandle } from '@backpackjs/storefront';

import { Image, Link, BackInStockModal, Spinner } from '@snippets';
import { useVariantPrices, useAddToCart } from '@hooks';

export function CartUpsellSlide({ closeCart, product }) {
  const { product: fullProduct } = useProductByHandle({
    handle: product?.handle,
  });
  const selectedVariant = fullProduct?.variants?.[0];

  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut },
    actions: { handleAddToCart, handleNotifyMe },
  } = useAddToCart({
    isCartUpsell: true,
    selectedVariant,
  });

  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const productUrl = fullProduct ? `/products/${fullProduct.handle}` : '';

  const image = fullProduct?.featuredImage;

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  return (
    <div className="flex items-center justify-center gap-4 px-8">
      <Link
        aria-label={fullProduct?.title}
        href={productUrl}
        onClick={closeCart}
        tabIndex="-1"
        className="w-16 bg-offWhite"
      >
        {image?.src && (
          <Image
            alt={fullProduct?.title}
            height={Math.floor(64 / (image?.width / image?.height))}
            src={image?.src}
            width="64"
          />
        )}
      </Link>

      <div className="flex max-w-[25rem] flex-1 flex-col gap-0">
        <Link
          aria-label={fullProduct?.title}
          href={productUrl}
          onClick={closeCart}
        >
          <h4 className="text-title-h6">{fullProduct?.title}</h4>
        </Link>

        {selectedVariant?.title !== 'Default Title' && (
          <p className="text-body-sm text-mediumGray">
            {selectedVariant?.title}
          </p>
        )}

        <div className="flex flex-col items-start justify-between gap-2">
          <button
            aria-label={buttonText}
            className={`btn-primary order-2 h-8 max-w-[175px] ${cursorClass}`}
            disabled={(isSoldOut && !isNotifyMe) || isLoading}
            onClick={() => {
              if (isNotifyMe) {
                handleNotifyMe(
                  <BackInStockModal selectedVariant={selectedVariant} />
                );
              } else {
                handleAddToCart();
              }
            }}
            type="button"
          >
            {isAdding ? (
              <div className="flex h-4 items-center justify-center px-6">
                <Spinner width="12" color="gray" />
              </div>
            ) : (
              <p>{isNotifyMe ? 'Notify Me' : buttonText}</p>
            )}
          </button>

          <div className="flex flex-1 flex-wrap justify-end gap-x-1">
            {compareAtPrice && (
              <p className="text-body-sm text-mediumGray line-through">
                {compareAtPrice}
              </p>
            )}
            <p
              className={`${
                compareAtPrice ? 'text-accent2' : 'text-text'
              } text-body-sm`}
            >
              {price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

CartUpsellSlide.displayName = 'CartUpsellSlide';
