import { COLORS } from '@settings/common';

export function Schema() {
  return {
    category: 'Slider',
    label: 'Press Slider',
    key: 'press-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/press-slider-preview.jpg?v=1675730332',
    fields: [
      {
        label: 'Slides',
        name: 'slides',
        component: 'group-list',
        description: 'Max of 6 slides',
        validate: {
          maxItems: 6,
        },
        itemProps: {
          label: '{{item.quote}}',
        },
        fields: [
          {
            label: 'Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Logo',
            name: 'image',
            component: 'image',
            description:
              'Image should be: in png or svg format; cropped to fit within a 3:2 viewbox; and no larger than 10kb',
          },
          {
            label: 'Quote',
            name: 'quote',
            component: 'textarea',
          },
        ],
        defaultItem: {
          alt: 'forbes logo',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/forbes_logo_small.png?v=1673633896',
          },
          quote:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
        },
        defaultValue: [
          {
            alt: 'forbes logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/forbes_logo_small.png?v=1673633896',
            },
            quote:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
          },
          {
            alt: 'vanity fair logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/vanity_fair_logo_small.png?v=1673633925',
            },
            quote:
              'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
          },
          {
            alt: 'forbes logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/forbes_logo_small.png?v=1673633896',
            },
            quote:
              'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.',
          },
          {
            alt: 'vanity fair logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/vanity_fair_logo_small.png?v=1673633925',
            },
            quote:
              'Excepteur sint occaecat cupidatat non proident, sunt in culpa.',
          },
          {
            alt: 'forbes logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/forbes_logo_small.png?v=1673633896',
            },
            quote:
              'Sed ut perspiciatis unde omnis iste natus error sit voluptatem.',
          },
          {
            alt: 'vanity fair logo',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/vanity_fair_logo_small.png?v=1673633925',
            },
            quote:
              'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width, background color, text color',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
        ],
        defaultValue: {
          fullWidth: false,
          bgColor: 'var(--background)',
          textColor: 'var(--text)',
        },
      },
    ],
  };
}
