import { useCallback, useState } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { Favorite, Link, ProductStars } from '@snippets';
import { ColorVariantSelector } from './ColorVariantSelector';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { QuickShop } from './QuickShop';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchPage,
  onClick,
  product: passedProduct,
  swatchesMap,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const [productFromColor, setProductFromColor] = useState(null);
  const [variantFromColor, setVariantFromColor] = useState(null);

  const selectedProduct = productFromColor || initialProduct;
  const selectedVariant = variantFromColor || selectedProduct?.variants?.[0];

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}`
    : '';
  const color = selectedVariant?.selectedOptionsMap?.Color;
  const title = selectedProduct?.title;

  const handleClick = useCallback(() => {
    PubSub.publish(
      isSearchPage ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
      {
        ...selectedVariant,
        image: selectedProduct?.images?.[0],
        index,
      }
    );
    if (typeof onClick === 'function') onClick();
  }, [index, selectedProduct?.id, selectedVariant?.id]);

  return (
    <div className="group flex h-full flex-col justify-between" ref={ref}>
      <div className="flex flex-col items-start">
        <Link
          aria-label={title}
          className="relative mb-3 w-full"
          href={productUrl}
          onClick={handleClick}
          tabIndex="-1"
        >
          <ProductItemMedia
            selectedProduct={selectedProduct}
            selectedVariant={selectedVariant}
          />
          <Favorite selectedVariant={selectedVariant} />
        </Link>

        {enabledStarRating && initialProduct?.legacyResourceId && (
          <div className="mb-1.5  inline-block min-h-[1.5rem] ">
            <Link
              aria-label={`Reviews for ${title}`}
              href={productUrl}
              onClick={handleClick}
              tabIndex="-1"
            >
              <ProductStars product={initialProduct} />
            </Link>
          </div>
        )}
        {color && <p className="text-sm text-mediumGray">{color}</p>}

        <Link aria-label={title} href={productUrl} onClick={handleClick}>
          <h2 className="text-title-h5 min-h-[1.5rem]">{title}</h2>
        </Link>

        <ProductItemPrice selectedVariant={selectedVariant} />

        {enabledColorSelector && (
          <ColorVariantSelector
            enabledColorNameOnHover={enabledColorNameOnHover}
            product={initialProduct}
            selectedVariant={selectedVariant}
            setProductFromColor={setProductFromColor}
            setVariantFromColor={setVariantFromColor}
            swatchesMap={swatchesMap}
          />
        )}
      </div>

      {enabledQuickShop && (
        <QuickShop
          enabledColorSelector={enabledColorSelector}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
        />
      )}
    </div>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
