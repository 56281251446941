import { Image, Link, Svg } from '@snippets';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
}) {
  const { imageLinks, links, menuItem } = { ...nestedSidebarContent };

  return (
    <div
      className={`scrollbar-hide absolute left-0 top-0 z-[1] h-full w-full bg-background ${
        nestedSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <div className="sticky top-0 z-[1] flex h-[3.5rem] w-full items-center justify-between gap-4 border-b border-b-border bg-background p-4">
          <button
            aria-label="Go back to main menu"
            className="w-5"
            onClick={() => handleNestedSidebar(null)}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/arrow-left.svg#arrow-left"
              title="Arrow Left"
              viewBox="0 0 24 24"
            />
          </button>

          <h3 className="text-title-h5 flex-1 text-center">{menuItem?.text}</h3>

          <button
            aria-label="Close menu sidebar"
            className="w-5"
            onClick={handleCloseSidebar}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>
        </div>

        <div className="px-4 pt-5">
          <ul className="mb-8 flex flex-col gap-2">
            {links?.map(({ link, sublinks, mobileText }, index) => {
              return (
                <li className="mb-6" key={index}>
                  <div className="mb-3">
                    <Link
                      aria-label={link?.text}
                      className="text-title-h5"
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                    >
                      {mobileText || link?.text}
                    </Link>
                  </div>
                  {!!sublinks?.length && (
                    <ul className="flex flex-col gap-y-4">
                      {sublinks.map(({ link }, subLinkIndex) => {
                        return (
                          <li key={subLinkIndex}>
                            <Link
                              aria-label={link?.text}
                              className="text-body"
                              href={link?.url}
                              newTab={link?.newTab}
                              onClick={handleCloseSidebar}
                            >
                              {link?.text}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>

          {imageLinks?.length > 0 && (
            <ul className="mb-8 flex flex-col gap-5">
              {imageLinks.map(({ alt, caption, image, link }, index) => {
                return (
                  <li key={index}>
                    <Link
                      aria-label={caption}
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                    >
                      <div className="relative aspect-[16/9] w-full overflow-hidden bg-offWhite">
                        {image?.src && (
                          <Image
                            alt={alt}
                            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                            height={Math.floor(768 / (16 / 9))}
                            src={image.src}
                            width="768"
                          />
                        )}
                      </div>

                      <p className="text-body mt-3">{caption}</p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="sticky bottom-0 z-[1]">
          <Link
            aria-label={menuItem?.text}
            className="btn-primary w-full rounded-none"
            href={menuItem?.url}
            newTab={menuItem?.newTab}
            onClick={handleCloseSidebar}
          >
            {menuItem?.text
              ? menuItem.text.toLowerCase() === 'shop'
                ? 'Shop All'
                : `All ${menuItem.text}`
              : ''}
          </Link>
        </div>
      </div>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
