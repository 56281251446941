import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';
import { useGlobalContext, useWishlistContext } from '@contexts';

export function Menu({
  handleOpenSidebar,
  handleCloseSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
  menuSidebarOpen,
}) {
  const customer = useCustomer();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const {
    state: { wishlist },
  } = useWishlistContext();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };

  return (
    <div className="px-contained relative z-[1] grid flex-1 grid-cols-[1fr_auto_1fr] gap-4 border-b border-b-border bg-background transition md:gap-8 lg:grid-cols-[auto_1fr_auto]">
      <div className="order-2 flex items-center lg:order-1">
        <Link aria-label="Go to homepage" href="/">
          <Svg
            className="h-10 text-text"
            src="/svgs/logo-primary.svg#logo-primary"
            title="Storefront logo"
            viewBox="0 0 3381 1187"
          />
        </Link>
      </div>

      <div className="order-1 flex items-center lg:order-2">
        <nav className="hidden h-full lg:flex">
          <ul className="flex" onMouseLeave={handleMenuHoverOut}>
            {menuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;
              const hasLinks = item?.links?.length;

              return (
                <li key={index} className="flex">
                  <Link
                    aria-label={item.menuItem?.text}
                    className={`group relative flex items-center px-4 transition ${
                      isHovered ? 'bg-offWhite' : 'bg-background'
                    }`}
                    href={item.menuItem?.url}
                    onClick={handleMenuDrawerClose}
                    onMouseEnter={() => hasLinks && handleMenuHoverIn(index)}
                  >
                    <p className="text-nav">{item.menuItem?.text}</p>

                    <div
                      className={`after:w-fill absolute left-0 top-[calc(100%_-_2px)] h-[3px] w-full origin-center scale-0 border-t-2 border-t-primary bg-transparent transition group-hover:scale-100 ${
                        isHovered ? 'scale-100' : 'scale-0'
                      }`}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex items-center gap-4">
          {menuSidebarOpen ? (
            <button
              aria-label="Close menu sidebar"
              className="w-5 lg:hidden"
              onClick={handleCloseSidebar}
              type="button"
            >
              <Svg
                className="w-full text-text"
                src="/svgs/close.svg#close"
                title="Close"
                viewBox="0 0 24 24"
              />
            </button>
          ) : (
            <button
              aria-label="Open menu"
              className="w-5 lg:hidden"
              onClick={handleOpenSidebar}
              type="button"
            >
              <Svg
                className="w-full text-text"
                src="/svgs/menu.svg#menu"
                title="Menu"
                viewBox="0 0 24 24"
              />
            </button>
          )}

          <button
            aria-label="Open search"
            className="block w-5 md:hidden"
            onClick={openSearch}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/search.svg#search"
              title="Search"
              viewBox="0 0 24 24"
            />
          </button>
        </div>
      </div>

      <div className="order-3 flex items-center justify-end gap-4 md:gap-5">
        <Link aria-label="Wishlist" href="/pages/wishlist" type="button">
          {wishlist?.length ? (
            <Svg
              className="w-5 fill-black"
              viewBox="0 0 72 72"
              alt="Favorite"
              src="/svgs/booty-full.svg#booty-full"
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          ) : (
            <Svg
              className="w-5"
              viewBox="0 0 72 72"
              alt="Favorite"
              src="/svgs/booty-empty.svg#booty-empty"
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          )}
        </Link>

        <button
          aria-label="Open search"
          className="hidden w-5 md:block"
          onClick={openSearch}
          type="button"
        >
          <Svg
            className="w-full text-text"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
        </button>

        <Link
          aria-label="Go to account page"
          href={customer ? '/account/orders' : '/account/login'}
          className="hidden w-5 text-text md:block"
        >
          <Svg
            className="w-full"
            src="/svgs/account.svg#account"
            title="Account"
            viewBox="0 0 24 24"
          />
        </Link>

        <div className="relative flex items-center">
          <button
            aria-label="Open cart"
            className="w-5"
            onClick={openCart}
            type="button"
          >
            <Svg
              className="w-full text-text"
              src="/svgs/cart.svg#cart"
              title="Cart"
              viewBox="0 0 24 24"
            />
          </button>

          <p className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
            ({cartCount || 0})
          </p>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
