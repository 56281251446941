import { useMemo } from 'react';

import { formatImageItemAsMediaItem } from '@utilities';

export function useProductItemMedia({ selectedProduct, selectedVariant }) {
  const hasNonGroupColorVariants =
    !selectedProduct?.grouping?.isTransformed &&
    selectedProduct?.optionsMap?.Color?.length > 1;

  // if multiple color variants from same product, use first media w/ color as alt
  const mediaMapByColor = useMemo(() => {
    if (!hasNonGroupColorVariants) return null;

    const colorOptions = selectedProduct.optionsMap?.Color;
    const colorKeys = colorOptions.map((color) => color.toLowerCase());

    return colorOptions.reduce((acc, color) => {
      let firstMediaIndex = 0;
      let secondMediaIndex = 1;
      const medias = selectedProduct?.media || selectedProduct?.images;
      const colorKey = color.toLowerCase();
      firstMediaIndex =
        medias.findIndex((item) => {
          const alt = (item.alt || item.altText)?.trim().toLowerCase();
          return alt === colorKey || !colorKeys.includes(alt);
        }) || 0;
      const secondMediaAlt = medias[firstMediaIndex + 1]?.alt
        ?.trim()
        .toLowerCase();
      secondMediaIndex =
        secondMediaAlt === colorKey || !colorKeys.includes(secondMediaAlt)
          ? firstMediaIndex + 1
          : -1;
      let media = [medias[firstMediaIndex], medias[secondMediaIndex]];
      if (!selectedProduct?.media) {
        // if shopify media transforms is off, shape image item similar to media item
        media = media.map((image) => {
          return formatImageItemAsMediaItem({
            image,
            product: selectedProduct,
          });
        });
      }
      return { ...acc, [color]: media };
    }, {});
  }, [selectedProduct?.id]);

  const selectedMedia = useMemo(() => {
    return hasNonGroupColorVariants && selectedVariant
      ? mediaMapByColor[selectedVariant.selectedOptionsMap.Color]
      : selectedProduct?.media?.slice(0, 2) ||
          // if shopify media transforms is off, shape image item similar to media item
          selectedProduct?.images?.slice(0, 2).map((image) => {
            return formatImageItemAsMediaItem({
              image,
              product: selectedProduct,
            });
          });
  }, [mediaMapByColor, selectedProduct?.id, selectedVariant?.id]);

  return {
    primaryMedia: selectedMedia?.[0],
    hoverMedia: selectedMedia?.[1],
  };
}
