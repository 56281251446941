const brand = process.env.NEXT_PUBLIC_SITE_TITLE || 'Storefront Name';

export const mapProductItem = (product, index = 0) => {
  try {
    if (!product) return null;
    const firstVariant = product.variants?.[0];

    return {
      id: firstVariant?.sku || '',
      name: product.title || '',
      brand,
      category: product.productType || '',
      variant: firstVariant?.title || '',
      price: firstVariant?.priceV2?.amount || '',
      list: window.location.pathname || '',
      product_id: product.legacyResourceId || '',
      variant_id: firstVariant?.legacyResourceId || '',
      compare_at_price: firstVariant?.compareAtPriceV2?.amount || '',
      image: product.images?.[0]?.src || '',
      position: index + 1,
    };
  } catch (error) {
    console.error('DataLayer:mapProductItem error', error.message);
    console.error('DataLayer:mapProductItem product', product);
    return null;
  }
};

export const productItemVariant = (variant, index = 0) => {
  try {
    if (!variant) return null;

    return {
      id: variant.sku || '',
      name: variant.product.title || '',
      brand,
      category: variant.product.productType || '',
      variant: variant.title || '',
      price: variant.priceV2?.amount || '',
      list: window.location.pathname || '',
      product_id: variant.product.legacyResourceId || '',
      variant_id: variant.legacyResourceId || '',
      compare_at_price: variant.compareAtPriceV2?.amount || '',
      image: variant.image?.src || '',
      position: index + 1,
    };
  } catch (error) {
    console.error('DataLayer:productItemVariant error', error.message);
    console.error('DataLayer:productItemVariant variant', variant);
    return null;
  }
};

export const productPageProduct = (product) => {
  try {
    if (!product) return null;
    const firstVariant = product.variants?.[0];

    let list = '';
    const { pathname } = window.location;
    const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
    if (pathname.startsWith('/collections')) {
      list = pathname;
    }
    if (pathname.startsWith('/products')) {
      list = previousPath?.startsWith('/collections') ? previousPath : '';
    }

    return {
      id: firstVariant?.sku || '',
      name: product.title || '',
      brand,
      category: product.productType || '',
      variant: firstVariant?.title || '',
      price: firstVariant?.priceV2?.amount || '',
      list,
      product_id: product.legacyResourceId || '',
      variant_id: firstVariant?.legacyResourceId || '',
      compare_at_price: firstVariant?.compareAtPriceV2?.amount || '',
      image: product.images?.[0]?.src || '',
      inventory: `${firstVariant?.quantityAvailable || ''}`,
    };
  } catch (error) {
    console.error('DataLayer:productPageProduct error', error.message);
    console.error('DataLayer:productPageProduct product', product);
    return null;
  }
};

const decodedId = (id) => {
  try {
    if (!id) return undefined;
    return window.atob(id);
  } catch (error) {
    return id;
  }
};

export const mapLineItem = (item, index = 0) => {
  try {
    const { quantity, variant } = { ...item };
    if (!variant) return null;

    return {
      id: variant.sku || '',
      name: variant.product?.title || '',
      brand,
      category: variant.product?.productType || '',
      variant: variant.title || '',
      price: variant.priceV2?.amount || '',
      quantity: `${quantity || ''}`,
      list: window.location.pathname,
      product_id: decodedId(variant.product?.id)?.split('/').pop() || '',
      variant_id: decodedId(variant.id)?.split('/').pop() || '',
      compare_at_price: variant.compareAtPriceV2?.amount || '',
      image: variant.image?.src || '',
      position: index + 1,
    };
  } catch (error) {
    console.error('DataLayer:mapLineItem error', error.message);
    console.error('DataLayer:mapLineItem item', item);
    return null;
  }
};

export const fetchCustomerOrders = async ({ accessToken }) => {
  try {
    if (!accessToken) return null;

    const url = `/api/customer/orders`;
    const request = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first: 100,
        country: 'US',
        accessToken,
      }),
    });

    if (!request?.ok) {
      return null;
    }

    const data = await request.json();
    const { ok, response } = data;

    if (!ok) {
      return null;
    }
    return response?.orders;
  } catch (error) {
    console.error('DataLayer:fetchCustomerOrders error', error.message);
    return null;
  }
};

export const trackKlaviyoEvent = async ({ email, event, properties }) => {
  if (!window.klaviyo) return null;

  const klaviyoIdentified = await klaviyo?.isIdentified();

  if (klaviyoIdentified) {
    window.klaviyo?.push(['track', event, properties]);
  } else if (email) {
    const identifyProfile = await klaviyo?.identify({ email });
    window.klaviyo?.push(['track', event, properties]);
  }
};
