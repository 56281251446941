import { useCallback, useEffect, useState } from 'react';
import { useCustomer } from '@backpackjs/storefront';

import { validateId } from '@utilities';

import { Schema } from './InfluenceRewardsPage.schema';

const isBrowser = typeof window !== 'undefined';

export function InfluenceRewardsPage() {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [customerIsReady, setCustomerIsReady] = useState(false);
  const [init, setInit] = useState(false);
  const [customerDigest, setCustomerDigest] = useState(null);

  const customer = useCustomer();

  const getHmacKey = useCallback(async () => {
    const id = validateId(customer?.id)?.split('/').pop();

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId: id,
        customerEmail: customer?.email,
      }),
    };

    const response = await fetch('/api/influence-io-hmac', options);
    const data = await response.json();
    return data;
  }, [customer]);

  useEffect(() => {
    const loadWidget = async () => {
      if (isBrowser && customer !== undefined) {
        if (customer?.id) {
          const { hmac } = await getHmacKey();
          setCustomerDigest(hmac);
        }
        setTimeout(() => {
          setCustomerIsReady(true);
        }, 1000);
      }
    };
    loadWidget();
  }, [customer]);

  /* needs to load a few seconds after customer is loaded */
  useEffect(() => {
    if (customerIsReady && !scriptIsLoaded) {
      const script = document.createElement('script');
      script.onload = setScriptIsLoaded(true);
      script.id = 'influence-widget-script';
      script.src = 'https://widgets.influence.io/dist/fullpage/main.js';
      document.head.appendChild(script);
    }

    return () => {
      document.getElementById('influence-widget-script')?.remove();
    };
  }, [customerIsReady, scriptIsLoaded]);

  useEffect(() => {
    if (!init && scriptIsLoaded) {
      setInit(true);
      setTimeout(() => {
        window.influenceioFullPageWidget();
      }, 1000);
    }
  }, [init, scriptIsLoaded]);

  return (
    <div>
      {(!init || !customerIsReady) && (
        <div className="flex min-h-[200px] items-center justify-center text-center">
          <div className="loader" />
        </div>
      )}

      <div className="LoyaltyFullpage-prefix">
        <div className="LoyaltyFullpage LoyaltyFullpage--minimal LoyaltyFullpage--fullpage js-demo-widget-theme">
          <div className="LoyaltyPage">
            <div className="LoyaltyPage__inner">
              <div
                className="R-ContentSection-container"
                id="influence-io-skeleton-loader"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-page-header"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-points-activity"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-rewards"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-earn-points"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-redeem-points"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-tiers"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-referral"
              />
              <div
                className="R-ContentSection-container"
                id="influence-io-affiliate-area"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="influenceio-shopify-init"
        data-shop="booty-by-brabants.myshopify.com"
        data-customer-email={customer?.email || null}
        data-customer-id={validateId(customer?.id)?.split('/').pop() || null}
        data-customer-digest={customerDigest || null}
      />
    </div>
  );
}

InfluenceRewardsPage.displayName = 'InfluenceRewardsPage';
InfluenceRewardsPage.Schema = Schema;
