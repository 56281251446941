import { useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';
import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';

export function Footer() {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };

  return (
    <footer
      className="md-px-contained border-t border-t-border py-8 md:py-12 xl:py-14"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <div className="mx-auto grid max-w-[var(--content-max-width)] grid-cols-1 md:grid-cols-[1fr_300px] md:gap-x-5 md:gap-y-10 xl:grid-cols-[200px_1fr_300px]">
        <div className="order-1 col-span-1 w-full md:col-span-2 xl:col-span-1">
          <div className="flex gap-6 border-b border-b-gray px-4 pb-8 md:border-none md:p-0 xl:flex-col">
            <Link href="/" aria-label="Go to home page">
              <Svg
                className="h-10 text-current"
                src="/svgs/logo-primary.svg#logo-primary"
                title="Storefront logo"
                viewBox="0 0 3381 1187"
              />
            </Link>

            <FooterSocial />
          </div>
        </div>

        <div className="order-3 w-full md:order-2">
          <FooterMenu />
        </div>

        <div className="order-2 w-full md:order-3">
          <EmailSignup />
        </div>

        <div className="order-4 col-span-1 w-full md:col-span-2 xl:col-span-3">
          <FooterLegal />
        </div>
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
