import { HeroSlide } from './HeroSlide';
import { HeroSlider } from './HeroSlider';
import { Schema } from './Hero.schema';

export function Hero({ cms }) {
  const { section, slider, slides } = cms;
  const cmsSectionId = cms?.cmsId || cms?.id;
  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const isFullBleed = section?.fullBleed;

  return (
    <div
      className={`section-${cmsSectionId} ${isFullBleed ? '' : 'px-contained'}`}
    >
      {/* to define hero height for SSG */}
      <style>
        {`
          .section-${cmsSectionId} .hero-container {
            height: ${
              (section?.heightMobile && typeof section.heightMobile === 'number'
                ? section.heightMobile
                : 600) /
              (448 / 100)
            }vw !important;
          }

          @media only screen and (min-width: 768px) {
            .section-${cmsSectionId} .hero-container {
              height: ${
                (section?.heightTablet &&
                typeof section.heightTablet === 'number'
                  ? section.heightTablet
                  : 600) /
                (767 / 100)
              }vw !important;
            }
          }

          @media only screen and (min-width: 1024px) {
            .section-${cmsSectionId} .hero-container {
              height: ${
                (section?.heightDesktop &&
                typeof section.heightDesktop === 'number'
                  ? section.heightDesktop
                  : 700) /
                (1440 / 100)
              }vw !important;
            }
          }
        `}
      </style>

      <div
        className={`hero-container relative mx-auto flex flex-col bg-offWhite ${maxWidthContainerClass}`}
      >
        {slides?.length > 1 && (
          <HeroSlider
            aboveTheFold={section?.aboveTheFold}
            slider={slider}
            slides={slides}
          />
        )}

        {slides?.length === 1 && (
          <HeroSlide
            aboveTheFold={section?.aboveTheFold}
            isFirstSlide
            isVisible
            slide={slides[0]}
          />
        )}
      </div>
    </div>
  );
}

Hero.displayName = 'Hero';
Hero.Schema = Schema;
