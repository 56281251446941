import { useCallback } from 'react';
import _uniqBy from 'lodash.uniqby';

export const useWishlist = () => {
  const updateWishlist = useCallback(async ({ params }) => {
    try {
      const response = await fetch('/api/swym', {
        method: 'post',
        body: JSON.stringify({
          action: 'updateListProducts',
          ...params,
        }),
      });

      const json = await response.json();

      const { data } = json;
      return data;
    } catch (error) {
      console.log('updateWishlist error:', error);
      return null;
    }
  }, []);

  const fetchUserWishlist = useCallback(async ({ email, cachedWishlist }) => {
    const endpoint = '/api/swym';
    try {
      const response = await fetch(endpoint, {
        method: 'post',
        body: JSON.stringify({
          action: 'getLists',
          email,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        let wishlist = data?.data?.find((list) => {
          return list.lname === 'wishlist';
        });

        if (!wishlist) {
          const createResponse = await fetch(endpoint, {
            method: 'post',
            body: JSON.stringify({
              action: 'createList',
              email,
            }),
          });

          const createData = await createResponse.json();
          wishlist = createData.data;
        }

        const { lid } = wishlist;

        const remoteWishlist = wishlist.listcontents
          ? wishlist.listcontents.reduce(
              (itemsObj, item) => {
                itemsObj.items.push({
                  empi: item.empi,
                  epi: item.epi,
                  du: item.du,
                });
                return itemsObj;
              },
              { items: [] }
            )
          : { items: [] };

        // merge local storage plus remote items
        const mergedWishlistItems = cachedWishlist?.items
          ? _uniqBy([...cachedWishlist.items, ...remoteWishlist.items], 'epi')
          : remoteWishlist.items;

        return { items: mergedWishlistItems, lid };
      }
      return { items: cachedWishlist?.items || [] };
    } catch (error) {
      console.error('fetchUserWishlist:', error.message);
      return null;
    }
  }, []);

  return { updateWishlist, fetchUserWishlist };
};
