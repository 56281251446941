import Script from 'next/script';

export function ProductSchemaMarkup({ pageUrl, product = {} }) {
  return (
    <>
      <Script
        id="schema-markup-product-breadcrumb"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
                item: process.env.SITE_URL || '',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: product.title || '',
                item: pageUrl,
              },
            ],
          }),
        }}
      />

      <Script
        id="schema-markup-product"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.title || '',
            image: product.images?.[0]?.src,
            description: product.description,
            brand: {
              '@type': 'Brand',
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            },
            offers: {
              '@type': 'Offer',
              url: pageUrl,
              priceCurrency: 'USD',
              price: product.priceRange?.minVariantPrice?.amount || '',
              availability: 'https://schema.org/InStock',
              itemCondition: 'https://schema.org/NewCondition',
            },
          }),
        }}
      />
    </>
  );
}

ProductSchemaMarkup.displayName = 'ProductSchemaMarkup';
