export const fetchProductFromHandle = async (passedHandle) => {
  try {
    const handle = passedHandle?.handle || passedHandle;
    if (!handle) return null;
    const endpoint = `/json/products/${handle}.json`;
    const response = await fetch(endpoint);
    return await response.json();
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const fetchProductsFromHandles = async (handles) => {
  try {
    if (!Array.isArray(handles) || !handles?.length) return [];
    const productPromises = handles.map(fetchProductFromHandle);
    return Promise.all(productPromises);
  } catch (error) {
    console.error(error.message);
    return [];
  }
};

export const formatImageItemAsMediaItem = ({ image, product }) => {
  if (!image || !product) return null;
  return {
    alt: image.altText,
    id: image.id,
    image,
    mediaContentType: 'IMAGE',
    preview: { image: { ...image, previewSrc: image.src } },
    product: { handle: product.handle },
  };
};
