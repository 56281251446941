import { useVariantPrices } from '@hooks';

export function ProductItemPrice({ selectedVariant }) {
  const { price, compareAtPrice, percentageSaved } = useVariantPrices({
    variant: selectedVariant,
  });

  return (
    <div className="mt-1 flex flex-1 flex-wrap gap-x-2">
      {compareAtPrice && (
        <p className="text-body text-mediumGray line-through">
          {compareAtPrice}
        </p>
      )}
      <p
        className={`${
          compareAtPrice ? 'text-accent2' : 'text-text'
        } text-body min-h-[22px]`}
      >
        {price}
      </p>
      {percentageSaved > 0 && (
        <p
          className={`${
            compareAtPrice ? 'text-accent2' : 'text-text'
          } text-body`}
        >
          {percentageSaved}% Off
        </p>
      )}
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
