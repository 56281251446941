import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { useWishlist } from '@hooks';

const Context = createContext();

const wishlistState = {
  wishlist: [],
  wishlistEmail: null,
  wishlistId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_WISHLIST':
      return {
        ...state,
        wishlist: action.payload.items,
        wishlistEmail: action.payload.email,
        wishlistId: action.payload.wishlistId,
      };
    case 'UPDATE_WISHLIST':
      return {
        ...state,
        wishlist: action.payload.items,
      };
    default:
      throw new Error(`Invalid Context action of type: ${action.type}`);
  }
};

const actions = (dispatch) => ({
  loadWishlist: ({ email, items, wishlistId }) => {
    dispatch({
      type: 'LOAD_WISHLIST',
      payload: { email, items, wishlistId },
    });
  },
  updateWishlist: (items) => {
    dispatch({ type: 'UPDATE_WISHLIST', payload: { items } });
  },
});

export function WishlistContextProvider({ children }) {
  const { fetchUserWishlist } = useWishlist();
  const [state, dispatch] = useReducer(reducer, { ...wishlistState });

  const value = useMemo(() => ({ state, actions: actions(dispatch) }), [state]);

  const customer = useCustomer();

  const getWishlistedProducts = useCallback(async (_wishlistEmail) => {
    let data;
    const cachedWishlist = window.localStorage.getItem('WISHLIST')
      ? JSON.parse(window.localStorage.getItem('WISHLIST'))
      : [];

    if (_wishlistEmail) {
      data = await fetchUserWishlist({
        email: _wishlistEmail,
        cachedWishlist,
      });
    } else {
      data = cachedWishlist || { items: [] };
    }

    if (Array.isArray(data?.items)) {
      dispatch({
        type: 'LOAD_WISHLIST',
        payload: {
          email: _wishlistEmail,
          items: data.items,
          wishlistId: data.lid,
        },
      });
    } else {
      dispatch({
        type: 'LOAD_WISHLIST',
        payload: {
          email: null,
          items: [],
          wishlistId: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    const _wishlistEmail = customer?.email || null;
    getWishlistedProducts(_wishlistEmail);
  }, [customer]);

  useEffect(() => {
    if (!state.email && !state.wishlistId) {
      window.localStorage.setItem(
        'WISHLIST',
        JSON.stringify({ items: state.wishlist })
      );
    }
  }, [state.wishlist]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useWishlistContext = () => useContext(Context);
