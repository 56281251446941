import React from 'react';

import { useCartAddDiscount } from '@backpackjs/storefront';

import { Markdown } from '@snippets';

export function PromoBarMessage({ color, props }) {
  const { message, discountCode } = { ...props };
  const { cartAddDiscount } = useCartAddDiscount();

  const markdownComponents = {
    // eslint-disable-next-line react/no-unstable-nested-components
    a: ({ node, ...props }) => {
      if (discountCode) {
        return (
          <a
            tabIndex={0}
            className="text-link-cta font-sans text-[11px] underline underline-offset-2 md:text-sm"
            onClick={() => cartAddDiscount({ discountCode })}
            onKeyDown={() => cartAddDiscount({ discountCode })}
            role="button"
            {...props}
          >
            {props.children}
          </a>
        );
      }

      return <a {...props}>{props.children}</a>;
    },
  };

  return (
    <div
      className="px-contained text-body-sm [&_a]:text-link-cta flex min-h-full items-center justify-center text-center [&_a]:font-sans [&_a]:text-[11px] [&_a]:underline-offset-2 [&_a]:md:text-xs"
      style={{ color }}
    >
      <Markdown components={markdownComponents}>{message}</Markdown>
    </div>
  );
}

PromoBarMessage.displayName = 'PromoBarMessage';
