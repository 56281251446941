export async function getPredictiveSearchData({ term }) {
  const query = `
      query PredictiveSearch(
        $searchTerm: String!
        $types: [PredictiveSearchType!]
      ){
        predictiveSearch(query: $searchTerm, types: $types) {
          collections {
            handle
          }
          queries {
            text
          }
        }
      }
    `;

  const variables = {
    searchTerm: term,
    types: ['COLLECTION', 'QUERY'],
  };

  const url = `https://${process.env.SHOPIFY_DOMAIN}/api/2023-10/graphql`;
  const body = JSON.stringify({ query, variables });

  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Shopify-Storefront-Access-Token':
        process.env.SHOPIFY_STOREFRONT_API_TOKEN,
    },
    body,
  });

  const json = await response.json();

  const { predictiveSearch } = { ...json?.data };

  const { collections = [], queries = [] } = { ...predictiveSearch };

  return { collections, queries };
}

export async function getProductSearchData({
  term,
  count = 250,
  endCursor = null,
  results = [],
}) {
  const query = `
      query ProductsSearch(
        $endCursor: String
        $first: Int
        $searchTerm: String!
      ){
        search(first: $first, query: $searchTerm, after: $endCursor, types: [PRODUCT]) {
          nodes {
            ... on Product {
              handle
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;

  const variables = {
    searchTerm: term,
    first: count,
    endCursor,
  };

  const url = `https://${process.env.SHOPIFY_DOMAIN}/api/2023-10/graphql`;
  const body = JSON.stringify({ query, variables });

  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Shopify-Storefront-Access-Token':
        process.env.SHOPIFY_STOREFRONT_API_TOKEN,
    },
    body,
  });

  const json = await response.json();

  const { search } = { ...json?.data };

  const { nodes = [], pageInfo } = { ...search };

  if (pageInfo?.hasNextPage && pageInfo.endCursor) {
    return getProductSearchData({
      term,
      endCursor: pageInfo.endCursor,
      results: [...results, ...nodes],
    });
  }

  const totalResults = [...results, ...nodes];

  return { products: totalResults };
}
