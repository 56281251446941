import { useEffect, useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { useColorSwatches, usePreserveScroll } from '@hooks';
import { CollectionFilters } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { CollectionSort } from './CollectionSort';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';

export function Collection({
  handle,
  products,
  productsReady,
  showHeading,
  title,
}) {
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchPage = handle === 'search';

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections, enabled }) => {
      if (enabled) {
        return collections?.some((colHandle) => colHandle.trim() === handle);
      }
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    products,
    productsReady,
  });

  const [resultsPerPage, setResultsPerPage] = useState(
    pagination?.resultsPerPage || 24
  );
  const [scrollY, setScrollY] = useState(null);
  console.log({ pagination, resultsPerPage });
  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    filtersMap: collectionFiltersData.state.filtersMap,
    handle,
    pagination: {
      ...pagination,
      resultsPerPage,
    },
    products,
    productsReady,
    promoTiles,
    colorGroups: filtersSettings?.colorGroups,
  });
  const totalProductsRevealed = collectionProductsData.state.productsLimit;

  let saveScrollTimeout;
  const saveScroll = () => {
    if (saveScrollTimeout) clearTimeout(saveScrollTimeout);

    saveScrollTimeout = setTimeout(() => {
      window.history.replaceState(
        {
          ...window.history.state,
          options: {
            ...window.history.state.options,
            scrollY: window.scrollY,
            totalProducts: totalProductsRevealed,
          },
        },
        ''
      );
    }, 50);
    console.log(window.history);
  };

  useEffect(() => {
    const { options } = window.history.state;
    if (options?.totalProducts) {
      setResultsPerPage(options?.totalProducts);
    }
    if (options?.scrollY) {
      setScrollY(options.scrollY);
    }
  }, []);

  useEffect(() => {
    if (scrollY) {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 10);
    }
  }, [productsReady, scrollY]);

  useEffect(() => {
    window.addEventListener('scroll', saveScroll);

    return () => {
      window.removeEventListener('scroll', saveScroll);
    };
  }, [totalProductsRevealed]);

  return (
    <div
      className={`mx-auto grid max-w-[var(--content-max-width)] grid-cols-2 ${
        enabledFilters
          ? 'md:grid-cols-[12rem_1fr] md:gap-x-6'
          : 'md:grid-cols-1'
      }`}
    >
      {showHeading && (
        <h1
          className={`text-title-h3 max-md:col-span-2 max-md:mb-4 max-md:mt-8 max-md:px-4 md:whitespace-nowrap ${
            !enabledSort ? 'md:col-span-2 md:mb-6' : ''
          }`}
        >
          {title}
        </h1>
      )}

      {enabledSort && (
        <div
          className={`max-md:order-2 md:mb-6 ${
            showHeading ? '' : 'md:col-span-2'
          } ${enabledFilters ? '' : 'max-md:col-span-2'}`}
        >
          <CollectionSort
            selectedSort={collectionProductsData.state.selectedSort}
            selectSort={collectionProductsData.actions.selectSort}
          />
        </div>
      )}

      {enabledFilters && (
        <div
          className={`${
            enabledSort
              ? 'max-md:order-1 max-md:border-r max-md:border-lightGray'
              : 'max-md:col-span-2'
          }`}
        >
          <CollectionFilters
            collectionFiltersData={collectionFiltersData}
            collectionCount={products?.length}
            swatchesMap={swatchesMap}
          />
        </div>
      )}

      <div className="max-md:order-3 max-md:col-span-2 max-md:mt-6">
        <CollectionGrid
          activeFilters={collectionFiltersData.state.activeFilters}
          collectionProductsData={collectionProductsData}
          enabledFilters={enabledFilters}
          isSearchPage={isSearchPage}
          promoTiles={promoTiles}
          swatchesMap={swatchesMap}
        />
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.defaultProps = {
  handle: undefined,
  products: [],
  productsReady: true,
  showHeading: false,
  title: '',
};
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
  showHeading: PropTypes.bool,
  title: PropTypes.string,
};
