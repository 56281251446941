import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

export function EmailSignup() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const settings = useSettings();
  const { enabled, listId, heading, subtext, placeholder, buttonText } = {
    ...settings?.footer?.marketing,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!email || isLoading) return;
      setIsLoading(true);

      if (!listId) {
        setIsLoading(false);
        setMessage('List ID is required');
        return;
      }

      try {
        const response = await fetch('/api/klaviyo', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            action: 'clientSubscribe',
            email,
            phone: null,
            source: window.location.origin + window.location.pathname,
            listId,
          }),
        });

        const data = await response.json();

        if (!response.ok && data.errors) {
          setMessage(data?.data || data?.errors);
          setIsLoading(false);
          return;
        }

        setMessage('Email submitted!');
        setIsLoading(false);
        setEmail('');
      } catch (error) {
        setIsLoading(false);
        setMessage('Please try again later');
        console.error(error);
      }
    },
    [email, listId, isLoading, message]
  );
  return enabled ? (
    <form
      className="border-b border-b-gray px-4 py-8 md:border-none md:p-0"
      onSubmit={handleSubmit}
    >
      <h3 className="text-nav text-current">{heading}</h3>

      {subtext && <p className="text-body mt-2 text-current">{subtext}</p>}

      <input
        className="input-text mt-3 text-text"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={placeholder}
        required
        type="email"
        value={email}
        disabled={isLoading}
      />

      <button
        aria-label={buttonText}
        className="btn-primary mt-3 w-full"
        type="submit"
        disabled={isLoading}
      >
        {buttonText}
      </button>
      {!!message?.length && (
        <p className="mt-3 text-sm font-semibold">{message}</p>
      )}
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';
