import { useSettings } from '@backpackjs/storefront';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMobileFilters } from './CollectionMobileFilters';

export function CollectionFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount, sticky } = {
    ...settings?.collection?.filters,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+1.5rem)]'
    : 'md:top-[calc(var(--header-height)+1.5rem)]';
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  return (
    <div
      className={`flex flex-col gap-5 md:sticky ${
        sticky ? stickyTopClass : ''
      }`}
    >
      {/* desktop */}
      <ul
        className={`border-lightGray max-md:hidden ${
          filters.length ? 'border-b' : ''
        }`}
      >
        {filters.map((filter, index) => {
          if (
            !filter.values.length ||
            (filter.values.length === 1 &&
              filter.values[0].count === collectionCount)
          )
            return null;

          return (
            <li key={index}>
              <CollectionDropdownFilter
                activeFilters={activeFilters}
                addFilter={addFilter}
                defaultOpen={filter.defaultOpen}
                filter={filter}
                optionsMaxCount={optionsMaxCount}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}
      </ul>

      {/* mobile */}
      <div className="md:hidden">
        <CollectionMobileFilters
          activeFilters={activeFilters}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filters={filters}
          filtersMap={filtersMap}
          optionsMaxCount={optionsMaxCount}
          removeFilter={removeFilter}
          showCount={showCount}
          swatchesMap={swatchesMap}
        />
      </div>

      <div className="hidden md:block">
        <CollectionFiltersSummary
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
        />
      </div>
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';
