import { useState, useMemo } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spinner, Svg } from '@snippets';
import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, section, tiles } = cms;

  const [swiper, setSwiper] = useState(null);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const gridColumnClasses = useMemo(() => {
    if (!tiles?.length) return 'grid-cols-2';
    if (tiles.length === 4) return 'grid-cols-4';
    if (tiles.length === 3) return 'grid-cols-3';
  }, [tiles]);

  return (
    <div className="lg-px-contained py-4 md:py-6">
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <h2 className="text-title-h2 mb-6 px-4 text-center md:mb-10">
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <>
            {/* mobile/tablet */}
            <div
              className={`relative lg:hidden ${!swiper ? 'min-h-[25rem]' : ''}`}
            >
              <Swiper
                grabCursor
                onSwiper={setSwiper}
                slidesOffsetAfter={16}
                slidesOffsetBefore={16}
                slidesPerView={1.4}
                spaceBetween={16}
                modules={[Navigation]}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  disabledClass: 'cursor-not-allowed opacity-20',
                }}
                breakpoints={{
                  768: {
                    slidesPerView: tiles.length >= 3 ? 2.4 : 1.4,
                    slidesOffsetBefore: 32,
                    slidesOffsetAfter: 32,
                    spaceBetween: 20,
                  },
                }}
              >
                {swiper &&
                  tiles.slice(0, 4).map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <ImageTile
                          aspectRatio={section?.aspectRatio}
                          content={content}
                          item={item}
                        />
                      </SwiperSlide>
                    );
                  })}

                <div className="swiper-button-prev left-2 !h-[3.5rem] !w-[3.5rem] rounded-full bg-white after:hidden lg:hidden">
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/arrow-left.svg#arrow-left"
                    title="Arrow Left"
                    viewBox="0 0 24 24"
                  />
                </div>

                <div className="swiper-button-next right-2 !h-[3.5rem] !w-[3.5rem] rounded-full bg-white after:hidden lg:hidden">
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/arrow-right.svg#arrow-right"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </div>
              </Swiper>

              {!swiper && (
                <Spinner
                  width="32"
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              )}
            </div>

            {/* desktop */}
            <div className={`hidden gap-x-5 lg:grid ${gridColumnClasses}`}>
              {tiles.slice(0, 4).map((item, index) => {
                return (
                  <div className="relative" key={index}>
                    <ImageTile
                      aspectRatio={section?.aspectRatio}
                      content={content}
                      item={item}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
