import { Accordions } from './Accordions';
import { Banner } from './Banner';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageTiles } from './ImageTiles';
import { InfluenceRewardsPage } from './InfluenceRewardsPage';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductRecommendations } from './ProductRecommendations';
import { ProductsSlider } from './ProductsSlider';
import { SearchResults } from './SearchResults';
import { SocialImagesGrid } from './SocialImagesGrid';
import { StoreReviews } from './StoreReviews';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';
import { VideoEmbed } from './VideoEmbed';
import { Wishlist } from './Wishlist';

export default [
  Accordions,
  Banner,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  FormBuilder,
  HalfHero,
  Hero,
  Html,
  IconRow,
  Image,
  ImageTiles,
  InfluenceRewardsPage,
  Markdown,
  PressSlider,
  ProductDetailAccordions,
  ProductRecommendations,
  ProductsSlider,
  SearchResults,
  SocialImagesGrid,
  StoreReviews,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  TwoTiles,
  Video,
  VideoEmbed,
  Wishlist,
];
