import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useProductsFromHandles } from '@backpackjs/storefront';

import { Collection, Spinner } from '@snippets';
import { useSearchProductResults } from '@hooks';
import { Schema } from './SearchResults.schema';

export function SearchResults() {
  const { query } = useRouter();
  const { productResults, totalProductResults } = useSearchProductResults({
    term: query.term,
  });
  // products for data layer event
  const { products } = useProductsFromHandles({
    handles: productResults?.slice(0, 7).map(({ handle }) => handle),
  });

  useEffect(() => {
    if (!products) return;
    PubSub.publish('VIEW_SEARCH_PAGE_RESULTS', products);
  }, [products]);

  return (
    <div className="md:px-contained py-contained">
      {totalProductResults > 0 && (
        <>
          <h1 className="text-title-h2 mx-auto mb-6 max-w-[50rem] text-center font-medium tracking-normal max-md:px-4 lg:mb-8">
            {query.term
              ? `Found ${totalProductResults} ${
                  totalProductResults === 1 ? 'result' : 'results'
                } for "${query.term}"`
              : null}
          </h1>

          <Collection
            handle="search"
            products={productResults}
            showHeading={false}
          />
        </>
      )}

      {totalProductResults === 0 && (
        <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
          {`Found 0 results for "${query.term || ''}"`}
        </h1>
      )}

      {!Array.isArray(productResults) && (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
